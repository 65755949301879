import React, { Component } from "react";
import Callout from "./Callout";
import Recipe from "./Recipe";
import FeaturedNews from "./../Feature/FeaturedNews";
import Pricing from "./Pricing";
import Banner from "./Banner";
import Features from "./../Feature/Features";
import FeatureCallout from "./../Feature/FeatureCallout";

class Homepage extends Component {
  render() {
    return (
      <div className="App">
        <Banner />
        <Features />
        <FeatureCallout />
        <Callout />
        <Pricing />
        <Recipe />
        <FeaturedNews />
      </div>
    );
  }
}

export default Homepage;
