import axios from "axios";
import { Api } from "../config";
import {
  requestEmployeeLogout,
  refreshTokens,
} from "./../store/actions/Auth/index";

const client = axios.create({
  baseURL: Api.BaseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

function getUrl(config) {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, "");
  }
  return config.url;
}

export const setupAxiosInterceptors = (store) => {
  // Intercept all request
  client.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.authReducer.tokens.access.token;
      console.log(accessToken, "accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        delete config.headers.Authorization;
        delete client.defaults.headers.Authorization;
      }
      console.log(
        `%c ${config.method.toUpperCase()} - ${getUrl(config)}:`,
        "color: #0086b3; font-weight: bold",
        config
      );
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Intercept all responses
  client.interceptors.response.use(
    async (response) => {
      console.log(
        `%c ${response.status} - ${getUrl(response.config)}:`,
        "color: #008000; font-weight: bold",
        response
      );
      return response;
    },
    async (error) => {
      const state = store.getState();
      const refreshToken = state.authReducer.tokens.refresh.token;
      if (refreshToken === null) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest.url.includes(Api.REFRESH_TOKENS)
      ) {
        // store.dispatch(requestEmployeeLogout());
        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const response = await axios.post(
          `${Api.BaseURL}/${Api.REFRESH_TOKENS}`,
          {
            refreshToken: refreshToken,
          }
        );

        const refreshResponse = response.data;
        if (response.status === 200) {
          client.defaults.headers.Authorization = `Bearer ${refreshResponse.access.token}`;
          originalRequest.headers.Authorization = `Bearer ${refreshResponse.access.token}`;
          await store.dispatch(refreshTokens(response.data));
          return client(originalRequest);
        } else {
          // store.dispatch(requestEmployeeLogout());
          return Promise.reject(error);
        }
      }

      console.log(
        // `%c ${error.response && error.response.status} - ${getUrl(error.response && error.response.config)}:`,
        `%c ${error} `,
        "color: #a71d5d; font-weight: bold",
        error.response
      );
      return Promise.reject(error);
    }
  );
};

export default client;
