import React from "react";
import PropTypes from "prop-types";

import "./glp-recipe-nutrition.scss";

GlpRecipeNutrition.propTypes = {
  nutrition: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.string,
    }).isRequired
  ),
};

function GlpRecipeNutrition({ nutrition, ...props }) {
  const _nutritionItem = () => {
    const nutritionList = nutrition.map((item) => (
      <div className="glp-recipe-nutrition__item mr-2" key={item.id}>
        <div className="glp-recipe-nutrition__item__name text-uppercase">
          {item.name && <span>{item.name}</span>}
        </div>
        <div className="glp-recipe-nutrition__item__quantity">
          {item.quantity && <span>{item.quantity}</span>}
        </div>
      </div>
    ));
    return <div className={"glp-recipe-nutrition__items"}>{nutritionList}</div>;
  };

  return (
    <div className={"glp-recipe-nutrition"}>
      <div className="glp-recipe-nutrition__title">Nutrition</div>
      <div className="glp-recipe-nutrition__body">
        {nutrition && _nutritionItem()}
      </div>
    </div>
  );
}

export default GlpRecipeNutrition;
