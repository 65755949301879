import React, { useState, useEffect } from "react";
import { Nav, Spinner } from "react-bootstrap";
import GlpInput from "./../../Gcomponents/GlpInput/GlpInput";
import GlpButton from "./../../Gcomponents/GlpButton/GlpButton";
import { Formik } from "formik";
import { INITIALS, STRINGS } from "./../../constants";
import { ValidationUtils } from "./../../utils";
import Errors from "./../Errors/Errors";
import { ReactComponent as Logo } from "./../../Gcomponents/GlpHeader/img/logo.svg";
import {
  requestEmployeeLogin,
  requestUserSignup,
  requestForgetPassword,
} from "./../../store/actions/Auth";
import GlpCheckbox from "./../../Gcomponents/GlpCheckbox/GlpCheckbox";
import GlpLink from "./../../Gcomponents/GlpLink/GlpLink";
import { PASSWORD_PATTERN } from "./../../utils/ValidationUtils";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "./../../store/actions/Auth/index";
import EmployeeLoginModal from "./../Modal/EmployeeLoginModal";
import { useHistory } from 'react-router-dom';

export const RightView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer);

  const formikRef = React.useRef();
  const [modalShow, setModalShow] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isValid, setIsValid] = useState({});

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  useEffect(() => {
    formikRef &&
      formikRef.current &&
      formikRef.current.setErrors({ password: authState.errors });
  }, [authState.errors]);

  useEffect(() => {
    formikRef &&
      formikRef.current &&
      formikRef.current.setErrors({ username: authState.errors });
  }, [authState.errors]);

  const handleValidation = (value) => {
    const pattern = PASSWORD_PATTERN;
    if (!pattern) {
      return true;
    }
    if (typeof pattern === "object") {
      const conditions = pattern.map((rule) => new RegExp(rule, "g"));
      return conditions.map((condition) => condition.test(value));
    }
  };

  return (
    <>
      <Nav.Link href="#link" className="glp-header__login__order">
        Online Ordering
      </Nav.Link>
      <GlpButton onClick={() => setModalShow(true)} label="LOGIN/REGISTER" />
      <EmployeeLoginModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        logo={<Logo />}
        input={() => (
          <Formik
            innerRef={formikRef}
            initialValues={INITIALS.loginModal}
            validationSchema={ValidationUtils.SignInValidation}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(requestEmployeeLogin(values.username, values.password, history, setModalShow));

            }}
            validateOnBlur={false}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className=" mt-2 my-3 mb-4 pb-2">
                      <GlpInput
                        label={STRINGS.homePage.loginModal.usernameLabel}
                        name={STRINGS.homePage.loginModal.username}
                        onChange={handleChange}
                        placeholder={
                          STRINGS.homePage.loginModal.usernamePlaceholder
                        }
                        value={values.username}
                      />
                      <Errors
                        error={errors.username}
                        showError={errors.username}
                      />
                    </div>
                    <div className="my-3">
                      <GlpInput
                        label={STRINGS.homePage.loginModal.passwordLabel}
                        name={STRINGS.homePage.loginModal.password}
                        onChange={handleChange}
                        placeholder={
                          STRINGS.homePage.loginModal.passwordPlaceholder
                        }
                        value={values.password}
                        icon
                        type="password"
                      />
                      <Errors
                        error={errors.password}
                        showError={errors.password}
                      />
                    </div>
                  </div>
                  <div className="text-right px-3 my-3 f-pass">
                    <span className="forgot">
                      <GlpLink
                        onClick={() => { }}
                        color="#f3521e"
                        underline={false}
                      >
                        {STRINGS.homePage.loginModal.forgetPassword}
                      </GlpLink>
                    </span>
                  </div>
                  <div className="glp-checkbox ml-3">
                    <GlpCheckbox
                      checked={checked}
                      id="secondary"
                      label="Remember me"
                      name="secondary-checkbox"
                      onChange={() => setChecked(!checked)}
                    />
                  </div>
                  <div className="mt-4 mb-5 pt-1 pb-3 px-3 text-right user-modal">
                    {authState.isLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <GlpButton onClick={handleSubmit} label="LOG IN" />
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      />
    </>
  );
};
