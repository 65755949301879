import React, { Component } from "react";
import GlpHeroBanner from "./../../Gcomponents/GlpHeroBanner/GlpHeroBanner";
import heroImage from "./../../Gcomponents/GlpHeroBanner/img/heroimage.jpg";

class Banner extends Component {
  render() {
    return (
      <GlpHeroBanner
        title="GROCERY LIST PLUS"
        description="Shopping for groceries will never be the same with the introduction of"
        backgroundImage={heroImage}
      />
    );
  }
}

export default Banner;
