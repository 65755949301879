import "./App.css";
import ProductManager from "./components/EmployeeInventory/ProductManager";
import ProductDetails from "./components/EmployeeInventory/ProductDetails/ProductDetails";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import { setupAxiosInterceptors } from "./api/client";
import { configureStore } from "./store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";
import Footer from "./components/Home/Footer";
import Homepage from "./components/Home/Homepage";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-7_August_2021_[v2]_MTYyODI5MDgwMDAwMA==ba9c3707c6dc30ffc9648e12a77a1171"
);

const { store } = configureStore();

function App() {
  const authReducer = useSelector((state) => state.authReducer);
  useEffect(() => {
    setupAxiosInterceptors(store);
  }, [authReducer.tokens]);

  return (
    <>
      <Header />
      <Switch>
        <Route path="/product-management">
          {authReducer.isLoggedIn ? <ProductManager /> : <Redirect to="/" />}
        </Route>
        <Route path="/product-details/:id">
          {authReducer.isLoggedIn ? <ProductDetails /> : <Redirect to="/" />}
        </Route>
        <Route path="/">
          <Homepage />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
