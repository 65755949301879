import React, { useState, useEffect, useRef } from "react";
import GlpInput from "./../../../Gcomponents/GlpInput/GlpInput";
import GlpLink from "./../../../Gcomponents/GlpLink/GlpLink";
import GlpButton from "./../../../Gcomponents/GlpButton/GlpButton";
import { useDispatch, useSelector } from "react-redux";
import {
  requestGenerateGlPin,
  updateSingleInventoryProduct,
} from "./../../../store/actions/ProductDetails/index";
import { INITIALS, STRINGS } from "../../../constants";
import { ValidationUtils } from "../../../utils";
import { Formik } from "formik";
import Errors from "../../Errors/Errors";
import { store } from "../../../store/store";
import { useHistory } from "react-router-dom";
import GlpLoader from "../../GlpLoader/GlpLoader";

function AssignGlpin(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const loadingReducer = useSelector((state) => state.loadingReducer);

  const singleInventoryData = useSelector(
    (state) => state.productDetailsReducer.singleInventoryData
  );

  useEffect(() => {
    formikRef &&
      formikRef.current &&
      formikRef.current.setErrors({
        productName: null,
        glpin: null,
        brandName: null,
        upc: null,
        size: null,
      });
    if (singleInventoryData && formikRef && formikRef.current) {
      const productDetails = singleInventoryData?.GlpProduct;

      formikRef.current.setFieldValue(
        STRINGS.productDetails.assignGlpin.productName,
        productDetails?.productName ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.assignGlpin.glpin,
        productDetails?.glpin ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.assignGlpin.brand,
        productDetails?.brandName ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.assignGlpin.upc,
        singleInventoryData?.upc ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.assignGlpin.size,
        productDetails?.size ?? ""
      );
    }
  }, [singleInventoryData]);

  const handleGenerateGlPin = () => {
    dispatch(requestGenerateGlPin());
    const unsubscribe = store.subscribe(() => {
      formikRef?.current?.setFieldValue(
        STRINGS.productDetails.assignGlpin.glpin,
        store.getState().productDetailsReducer.glpin
      );
      unsubscribe();
    });
  };

  const [type, setType] = useState(null);

  return (
    <>
      <h5>Assign GL Pin</h5>
      {singleInventoryData ? (
        <Formik
          innerRef={formikRef}
          initialValues={INITIALS.assignGlpin}
          validationSchema={ValidationUtils.AssignGlpinValidation}
          onSubmit={(values, { setSubmitting, isSubmitting }) => {
            console.log("ddd");
            const productId = singleInventoryData?.GlpProduct.id;
            dispatch(updateSingleInventoryProduct(values, productId));
            type === "exit"
              ? history.push("/product-management")
              : props.setKey("productInfoUpdate");
          }}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <form>
                <div className="form-group">
                  <div className="border-b">
                    <div className="row">
                      <div className="col-md-7">
                        <GlpInput
                          label={STRINGS.productDetails.assignGlpin.glpinLabel}
                          name={STRINGS.productDetails.assignGlpin.glpin}
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpin}
                          disabled={!!values.glpin}
                        />
                        <Errors error={errors.glpin} showError={errors.glpin} />
                      </div>
                      <div className="col-md-3">
                        {!values.glpin ? (
                          <div className="mt-4 pt-2">
                            <GlpLink
                              color="#248937"
                              underline
                              onClick={handleGenerateGlPin}
                            >
                              Generate GL Pin
                            </GlpLink>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-7">
                        <div className="mb-3">
                          <GlpInput
                            label={
                              STRINGS.productDetails.assignGlpin
                                .productNameLabel
                            }
                            name={
                              STRINGS.productDetails.assignGlpin.productName
                            }
                            onChange={handleChange}
                            variant="flat"
                            value={values.productName}
                          />
                          <Errors
                            error={errors.productName}
                            showError={errors.productName}
                          />
                        </div>

                        <div className="mb-3">
                          <GlpInput
                            label={
                              STRINGS.productDetails.assignGlpin.brandLabel
                            }
                            name={STRINGS.productDetails.assignGlpin.brand}
                            onChange={handleChange}
                            variant="flat"
                            value={values.brandName}
                          />
                          <Errors
                            error={errors.brandName}
                            showError={errors.brandName}
                          />
                        </div>
                        <div className="mb-3">
                          <GlpInput
                            label={STRINGS.productDetails.assignGlpin.upcLabel}
                            name={STRINGS.productDetails.assignGlpin.upc}
                            onChange={handleChange}
                            variant="flat"
                            value={values.upc}
                          />
                          <Errors error={errors.upc} showError={errors.upc} />
                        </div>
                        <div className="mb-3">
                          <GlpInput
                            label={STRINGS.productDetails.assignGlpin.sizeLabel}
                            name={STRINGS.productDetails.assignGlpin.size}
                            onChange={handleChange}
                            variant="flat"
                            value={values.size}
                          />
                          <Errors error={errors.size} showError={errors.size} />
                        </div>
                        <div className="mb-3">
                          <GlpInput
                            label={
                              STRINGS.productDetails.assignGlpin.glpinLabel
                            }
                            name={STRINGS.productDetails.assignGlpin.glpin}
                            onChange={handleChange}
                            variant="flat"
                            value={values.glpin}
                            disabled={!!values.glpin}
                          />
                          <Errors
                            error={errors.glpin}
                            showError={errors.glpin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-8 text-right">
                        <div className="mt-4 d-flex justify-content-end">
                          <div>
                            <GlpButton
                              color="secondary"
                              label="SAVE AND EXIT"
                              onClick={(e) => {
                                setType("exit");
                                handleSubmit(e);
                              }}
                              variant="outline"
                            />
                          </div>
                          <div className="ml-3">
                            <GlpButton
                              label="Save And Continue"
                              onClick={(e) => {
                                setType("continue");
                                handleSubmit(e);
                              }}
                              variant="outline"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <div>
          <GlpLoader />
        </div>
      )}
      {loadingReducer.isLoading && <GlpLoader />}
    </>
  );
}

export default AssignGlpin;
