export const STRINGS = {
  homePage: {
    loginModal: {
      validation: {
        email: {
          required: "This field cannot be empty",
          valid: "Enter a valid email",
        },
        username: {
          required: "This field cannot be empty",
          valid: "Enter a valid username",
        },
        password: {
          required: "This field cannot be empty",
          valid: "Password should be at least 8 characters",
        },
      },
      emailLabel: "Email Address",
      emailPlaceholder: "your_email@example.com",
      usernameLabel: "User ID",
      usernamePlaceholder: "Your ID",
      continueAsGuest: "CONTINUE AS GUEST",
      modeOutline: "outline",
      passwordLabel: "Password",
      passwordPlaceholder: "Your password",
      password: "password",
      username: "username",
      email: "email",
      loginButtonTitle: "Log In",
      forgetPassword: "Forgot Password?",
      loginSuccess: "Login Successful",
    },
    registerModal: {
      validation: {
        email: {
          required: "This field cannot be empty",
          valid: "Enter a valid email",
        },
        password: {
          required: "This field cannot be empty",
          valid: "Password should be at least 8 characters",
        },
        firstName: {
          required: "This field cannot be empty",
        },
        lastName: {
          required: "This field cannot be empty",
        },
        zipCode: {
          required: "This field cannot be empty",
        },
        termsid: {
          required: "This field cannot be empty",
        },
      },
      continueAsGuest: "CONTINUE AS GUEST",
      firstNameLabel: "First Name",
      firstNamePlaceholder: "First Name",
      modeOutline: "outline",
      firstName: "firstName",

      lastNameLabel: "Last Name",
      lastNamePlaceholder: "Last Name",
      lastName: "lastName",

      secondNameLabel: "Second Name",
      secondNamePlaceholder: "Second Name",
      secondName: "secondName",

      emailLabel: "Email Address",
      emailPlaceholder: "your_email@example.com",
      email: "email",

      zipCodeLabel: "Zip code",
      zipCodePlaceholder: "xxxxxx",
      zipCode: "zipCode",

      passwordLabel: "Password",
      passwordPlaceholder: "Your password",
      password: "password",
      termsid: "termsid",
      yourPasswordMustHave: "Your password must have:",
      charactersInLength: "8 characters in length",
      lowerCaseLetter: "Lower case letter (a-z)",
      upperCaseLetter: "Upper case letter (A-Z)",
      numbers: "Numbers (0-9)",
      specialCharacters: "Special characters (~!@#$%^&*())",
      registerButtonTitle: "Register",
      iAgreeToThe: " I agree to the",
      termsAndConditions: "Terms and Conditions",
      registrationSuccess: "Registration Successful",
      passwordRules: [
        "8 characters in length",
        "Lower case letters (a-z)",
        "Upper case letters (A-Z)",
        "Numbers (0-9)",
        "Special characters (~!@#$%^&*())",
      ],
    },
  },
  productDetails: {
    assignGlpin: {
      validation: {
        glpin: {
          required: "Glpin cannot be empty",
        },
        productName: {
          required: "Product Name cannot be empty",
        },
        brandName: {
          required: "Brand Name cannot be empty",
        },
        upc: {
          required: "UPC cannot be empty",
        },
        size: {
          required: "Size cannot be empty",
        },
      },
      glpinLabel: "GL Pin",
      glpin: "glpin",
      productNameLabel: "Product Name",
      productName: "productName",
      brandLabel: "Brand",
      brand: "brandName",
      upcLabel: "UPC",
      upc: "upc",
      sizeLabel: "Size",
      size: "size",
    },
    productInformation: {
      validation: {
        brandName: {
          required: "Brand Name cannot be empty",
        },
        productName: {
          required: "Product Name cannot be empty",
        },
        systemDept: {
          required: "System Department cannot be empty",
        },
        priceAvg: {
          required: "Price cannot be empty",
        },
        systemCategory: {
          required: "Category cannot be empty",
        },
        productDescriptionShort: {
          required: "Product Description cannot be empty",
        },
        // ingredients: {
        //   required: "Ingredients cannot be empty",
        // },
      },
      productNameLabel: "Product Name",
      productName: "productName",
      brandLabel: "Brand",
      brand: "brandName",
      departmentLabel: "Department",
      department: "systemDept",
      priceLabel: "Price",
      price: "priceAvg",
      categoryLabel: "Category",
      category: "systemCategory",
      productDescLabel: "Product Description",
      productDesc: "productDescriptionShort",
      ingredientsLabel: "Ingredients",
      ingredients: "ingredients",
      instructionsLabel: "Instructions",
      instructions: "instructions",
    },
    nutritionInformation: {
      servingSizeLabel: "Serving Size",
      servingSize: "glpServingSizeQty",
      servingPerContainerLabel: "Serving Per Container",
      servingPerContainer: "glpServingsPerContainer",
      caloriesLabel: "Calories",
      calories: "glpCalories",
      caloriesFromFatLabel: "Calories From Fat",
      caloriesFromFat: "glpCaloriesFromFat",
      totalFatLabel: "Total Fat 2.5g",
      totalFat: "glpTotalFat",
      saturatedFatLabel: "Saturated Fat 0g",
      saturatedFat: "glpSaturatedFat",
      polyUnsaturatedFatLabel: "Polyunsaturated Fat 0.5g",
      polyUnsaturatedFat: "glpPolyunsaturatedFat",
      monoUnsaturatedFatLabel: "Monounsaturated Fat 1.5g",
      monoUnsaturatedFat: "glpMonounsaturatedFat",
      cholesterolLabel: "Cholesterol 0mg",
      cholesterol: "glpCholesterol",
      sodiumLabel: "Sodium 420mg",
      sodium: "glpSodium",
      potassiumLabel: "Potassium 340mg",
      potassium: "glpPotassiumDv",
      carbohydrateLabel: "Total Carbohydrate 11",
      carbohydrate: "glpTotalCarbohydrate",
      dietaryFiberLabel: "Dietary Fiber 2g",
      dietaryFiber: "glpDietaryFiber",
      sugarLabel: "Sugar 8g",
      sugar: "glpSugars",
      proteinLabel: "Protein",
      protein: "glpProtein",
      vitaminALabel: "Vitamin A",
      vitaminA: "glpVitaminADv",
      vitaminCLabel: "Vitamin C",
      vitaminC: "glpVitaminCDv",
      calciumLabel: "Calcium2",
      calcium: "glpCalciumDv",
      ironLabel: "Iron4",
      iron: "glpIronDv",
      vitaminDdLabel: "Vitamin D0",
      vitaminDd: "glpVitaminDDv",
    },
  },
};
