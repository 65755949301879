import React from "react";
import PropTypes from "prop-types";
import "./glp-checkbox.scss";

GlpCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  primary: PropTypes.bool.isRequired,
  large: PropTypes.bool.isRequired,
  round: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

GlpCheckbox.defaultProps = {
  round: false,
  large: false,
  primary: true,
  checked: false,
};

function GlpCheckbox({
  id,
  labelStyle,
  large,
  primary,
  label,
  round,
  ...props
}) {
  return (
    <div
      className={`glp-checkbox 
         glp-checkbox--svg 
         glp-checkbox--${round ? "round" : "curve"}
         glp-checkbox--${large ? "bigger" : "base"}
      `}
    >
      <input id={id} type="checkbox" {...props} />
      <div
        className={`glp-checkbox-state 
            glp-checkbox--${primary ? "primary" : "secondary"}`}
      >
        <svg className="glp-checkbox-svg" viewBox="0 0 20 20">
          <path
            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            style={{ stroke: "#fff", fill: "#fff" }}
          />
        </svg>
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
}

export default GlpCheckbox;
