import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { contractDetails } from "./data/contractDetails";
import "./../assets/custom.css";
import GlpLink from "./../../Gcomponents/GlpLink/GlpLink";
import ContractDetails from "./../Modal/ContractDetails";
import DeleteModal from "./../Modal/DeleteModal";
import ProductActionItems from "./ProductActionItems";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./../../store/actions/InitialInventory";
import moment from "moment";
import { store } from "../../store/store";
import { useHistory } from "react-router-dom";
import { setProductsForEditing } from "../../store/actions/InitialInventory/actions";
import "./Products.scss";

function Productss() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [productSearchQuery, setProductSearchQuery] = useState(null);
  const btnRef = useRef();
  const rowHeight = 54;
  const headerHeight = 46;
  let rows = [];
  const initialInventoryReducer = useSelector(
    (state) => state.initialInventory
  );

  const products = useSelector((state) => state.initialInventory.products);
  const [productsState, setroductsState] = useState(products);
  const [selectedRowId, setSelectedRowId] = useState("");

  useEffect(() => {
    dispatch(actions.fetchAllInitialInventory(pageNumber));
    dispatch(setProductsForEditing([]));
  }, []);
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    const unsubscibe = store.subscribe(() => {
      setroductsState(store.getState().initialInventory.products);
    });
    return unsubscibe;
  }, []);

  useEffect(() => {
    let selectedNodes = gridApi?.getSelectedNodes();
    let selectedDataIds = selectedNodes?.map((node) => node.data.id) || [];

    if (!selectedDataIds.length) {
      dispatch(setProductsForEditing([selectedRowId], history));
    } else dispatch(setProductsForEditing(selectedDataIds), history);

    const unSubscribe = store.subscribe(() => {
      const shouldRoute =
        store.getState().initialInventory.selectedProductsForEditing[0] != "";
      if (shouldRoute) {
        history.push("/product-details");
      }
    });
    return unSubscribe;
  }, [selectedRowId]);

  useEffect(() => {
    const unSubscribe = store.subscribe(() => {
      const shouldRoute =
        store.getState().initialInventory.selectedProductsForEditing[0] != "";
      if (shouldRoute) {
        history.push("/product-details");
      }
    });
    return unSubscribe;
  }, []);

  rows = productsState.data.map((product) => {
    const productDetails = product.productDetails;
    const date = moment(productDetails?.updateHistory[0].updateDate);
    return {
      upc: product.upc,
      brand: productDetails?.brand_name,
      productName: productDetails?.product_name,
      productSize: productDetails?.size,
      contractId: product.contractId,
      glpin: productDetails?.glpin,
      lastModDate: date.format("D.MM.YY"),
      lastModId: productDetails?.updateHistory[0]._id,
      actions: "",
      id: product.id,
    };
  });
  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 1) {
      return { background: "#F1F1F1" };
    }
  };

  const [state, setState] = useState({
    modalShow: false,
    deleteModalShow: false,
    columnDefs: [
      {
        headerName: "UPC",
        field: "upc",
        minWidth: 160,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellStyle: {
          color: "#F67A1A",
          fontSize: "12px",
          border: "none !important",
          outline: "none",
          fontWeight: 600,
        },
        className: "upc",
      },
      {
        headerName: "Brand",
        field: "brand",
        minWidth: 150,
        cellStyle: {
          color: "#6D838B",
          fontSize: "12px",
          border: "none !important",
          outline: "none",
        },
        cellClass: "brand",
      },
      {
        headerName: "Product name",
        field: "productName",
        minWidth: 150,
        cellStyle: {
          color: "#248937",
          fontSize: "12px",
          border: "none !important",
          outline: "none",
        },
        cellClass: "product-name",
      },
      {
        headerName: "Product size",
        field: "productSize",
        mmaxWidth: 10,
        cellClass: "size",
      },
      {
        headerName: "Contract ID",
        field: "contractId",
        minWidth: 220,
        cellClass: "contract-id",
        cellRendererFramework: function (params) {
          return (
            <>
              <GlpLink
                onClick={() => openContract()}
                color="#EC1C24"
                underline={false}
              >
                {params.data.contractId}
              </GlpLink>
            </>
          );
        },
      },
      {
        headerName: "glpin",
        field: "glpin",
        maxWidth: 100,
        cellClass: "glpin",
      },
      {
        headerName: "Last Mod. Date",
        field: "lastModDate",
        minWidth: 80,
        maxWidth: 120,
        cellStyle: {
          color: "#F67A1A",
          fontSize: "12px",
          border: "none !important",
          outline: "none",
        },
        cellClass: "last-modified-date",
      },
      {
        headerName: "Last Mod. Id",
        field: "lastModId",
        minWidth: 200,
        cellClass: "last-modified-id",
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 50,
        cellStyle: {
          border: "none !important",
          outline: "none",
        },
        cellRenderer: "productActionItems",
        cellRendererParams: {
          clicked: (field) => {
            if (field.actionBtn === "edit") {
              setSelectedRowId(field.id);
            } else if (field.actionBtn === "delete") {
              deleteProduct();
            }
          },
        },
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
    },
    paginationPageSize: 10,
    maxConcurrentDatasourceRequests: 2,
    rowSelection: "multiple",
    frameworkComponents: {
      productActionItems: ProductActionItems,
    },
    rowData: rows,
  });
  const openContract = () => {
    setState((prevState) => {
      return { ...prevState, modalShow: true };
    });
  };
  const deleteProduct = () => {
    setState((prevState) => {
      return { ...prevState, deleteModalShow: true };
    });
  };
  const onPaginationChanged = () => {
    if (gridApi) {
      // gridApi.paginationSetPageSize(Number(17));
      // setPageNumber(gridApi?.paginationGetCurrentPage() + 2);
      // console.log("page-----------------", pageNumber);
      // dispatch(actions.fetchAllInitialInventory(pageNumber));
    }
  };
  const onFilterTextBoxChanged = () => {
    gridApi?.setQuickFilter(
      document.querySelector(".glp-search__view__text.glp-search-view-text1")
        .value
    );
  };

  useEffect(() => {
    const unSubscribe = store.subscribe(() => {
      if (gridApi)
        gridApi.setQuickFilter(
          document.querySelector(
            ".glp-search__view__text.glp-search-view-text1"
          ).value
        );
    });
    return unSubscribe;
  });

  useEffect(() => {
    onFilterTextBoxChanged();
  }, [productSearchQuery]);

  return (
    <>
      {/* <button ref={btnRef} onClick={getSelectedRowData} className="d-none"></button> */}
      <div style={{ width: "100%", height: "100%" }}>
        <div className="initial-inventory">
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <div className="ag-material" style={{ height: "500px" }}>
              <AgGridReact
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                columnDefs={state.columnDefs}
                defaultColDef={state.defaultColDef}
                frameworkComponents={state.frameworkComponents}
                suppressRowClickSelection={true}
                rowSelection={state.rowSelection}
                onGridReady={onGridReady}
                rowData={state.rowData}
                pagination={true}
                paginationPageSize={state.paginationPageSize}
                getRowStyle={getRowStyle}
                onPaginationChanged={onPaginationChanged}
                maxConcurrentDatasourceRequests={
                  state.maxConcurrentDatasourceRequests
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ContractDetails
        show={state.modalShow}
        onHide={() => {
          setState((prevState) => {
            return { ...prevState, modalShow: false };
          });
        }}
        details={contractDetails}
      />
      <DeleteModal
        show={state.deleteModalShow}
        onHide={() => {
          setState((prevState) => {
            return { ...prevState, deleteModalShow: false };
          });
        }}
      />
    </>
  );
}

export default Productss;
