import React, { Component } from "react";
import "./../assets/custom.css";
import MainTabs from "./../Tabs/MainTabs";
import { tabs } from "./data/tabs";
import GlpSearch from "./../../Gcomponents/GlpSearch/GlpSearch";
import { ReactComponent as AddProduct } from "./img/addProduct.svg";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../store/actions/InitialInventory/actions";

function ProductManager() {
  const dispatch = useDispatch();
  return (
    <>
      <div className="main-bg product-management">
        <div className="table-container">
          <div className="row d-flex justify-content-center">
            <div className="product-table-body mb-4">
              <div className="glp-profile-relative glp-grid-view border">
                <div className="glp-top-section">
                  <div
                    id="features"
                    className="glp-profile-section d-flex justify-content-between mr-lg-4"
                  >
                    <h1 className="text-md-nowrap page-headings">
                      Product Management
                    </h1>
                    {/* <GlpSearch
                      data={[]}
                      itemCell={() => { }}
                      mode="button"
                      onChange={() => document.querySelector('.glp-search__view__text.glp-search-view-text1').value === '' ? dispatch(setSearchQuery(document.querySelector('.glp-search__view__text.glp-search-view-text1').value)) : null}
                      placeholder="Search by Product name, UPC, Contract ID, glpin"
                      searchButton="Search"
                    // className="search__input"
                    /> */}
                  </div>
                  <div className="glp-add-btn">
                    <button type="button">Add New Products</button>
                    <AddProduct />
                  </div>
                </div>
                <MainTabs items={tabs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductManager;
