export const GET_SINGLE_INVENTORY = "GET_SINGLE_INVENTORY"
export const SINGLE_INVENTORY_SUCCESSS = 'SINGLE_INVENTORY_SUCCESSS'
export const SINGLE_INVENTORY_FAILED = 'SINGLE_INVENTORY_FAILED'

export const UPDATE_MAIN_IMAGE = 'UPDATE_MAIN_IMAGE'
export const UPDATE_MAIN_IMAGE_SUCCESS = 'UPDATE_MAIN_IMAGE_SUCCESS'
export const UPDATE_MAIN_IMAGE_FAILED = 'UPDATE_MAIN_IMAGE_FAILED'

export const UPDATE_PRODUCT_THUMBNAIL = 'UPDATE_PRODUCT_THUMBNAIL'
export const UPDATE_PRODUCT_THUMBNAIL_SUCCESS = 'UPDATE_PRODUCT_THUMBNAIL_SUCCESS'
export const UPDATE_PRODUCT_THUMBNAIL_FAILED = 'UPDATE_PRODUCT_THUMBNAIL_FAILED'

export const ADD_OTHER_IMAGES_FOR_PRODUCT = 'ADD_OTHER_IMAGES_FOR_PRODUCT'
export const ADD_OTHER_IMAGES_FOR_PRODUCT_SUCCESS = 'ADD_OTHER_IMAGES_FOR_PRODUCT_SUCCESS'
export const ADD_OTHER_IMAGES_FOR_PRODUCT_FAILED = 'ADD_OTHER_IMAGES_FOR_PRODUCT_FAILED'

export const RESET_ERRORS = "RESET_ERRORS";
export const UPDATE_SINGLE_INVENTORY = "UPDATE_SINGLE_INVENTORY";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_INVENTORY_FAILED = "UPDATE_INVENTORY_FAILED";
export const UPDATE_SINGLE_INVENTORY_NUTRITION = "UPDATE_SINGLE_INVENTORY_NUTRITION";
export const UPDATE_INVENTORY_NUTRITION_SUCCESS = "UPDATE_INVENTORY_NUTRITION_SUCCESS";
export const UPDATE_INVENTORY_NUTRITION_FAILED = "UPDATE_INVENTORY_NUTRITION_FAILED";

export const GENERATE_GL_PIN = 'GENERATE_GL_PIN'
export const GENERATE_GL_PIN_SUCCESS = 'GENERATE_GL_PIN_SUCCESS'
export const GENERATE_GL_PIN_FAILED = 'GENERATE_GL_PIN_FAILED'
