import React from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { setProductsForEditing } from '../../store/actions/InitialInventory/actions';
// import { requestSingleInventory } from '../../store/actions/ProductDetails';
import { ReactComponent as Delete } from './img/delete.svg';
import { ReactComponent as Edit } from './img/edit.svg';
import { ReactComponent as Preview } from './img/preview.svg';

function ProductActionItems(props) {
  // console.log(props, 'cell props')
  // const dispatch = useDispatch();
  // const selectedProductsIdsForEditing = useSelector(state => state.initialInventory)
  // console.log(selectedProductsIdsForEditing.selectedProductsForEditing, 'selectedProductsIdsForEditing')
 
  const handleEditProduct = () => {
    props.clicked({ actionBtn: 'edit', id: props.data.id });
  }

  const handleDeleteProduct = () => {
    props.clicked({ actionBtn: 'delete' });

  }
  return (
    <div className="row align-center">
      <div className="d-flex">
        <div className="mr-3">
          <Edit onClick={handleEditProduct} className="cursor-pointer"/>
        </div>
        <div className="mr-3">
          <Preview className="cursor-pointer"/>
        </div>
        <div className="mr-0">
          <Delete onClick={handleDeleteProduct} className="cursor-pointer"/>
        </div>
      </div>
    </div>
  );
}

export default ProductActionItems;
