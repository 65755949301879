import * as Yup from "yup";
import { STRINGS } from "../constants";

export const PASSWORD_PATTERN = [
  "^.{8,}$", // min 8 chars
  "(?=.*?[a-z])", // lowercase letter
  "(?=.*[A-Z])", // uppercase letter
  "(?=.*\\d)", // number required
  "(?=.*[#$@!%&*?])", // special letter
];

export const SignInValidation = Yup.object().shape({
  username: Yup.string()
    .required(STRINGS.homePage.loginModal.validation.username.required)
    .min(3, STRINGS.homePage.loginModal.validation.username.valid),
  password: Yup.string()
    .required(STRINGS.homePage.loginModal.validation.password.required)
    .min(8, STRINGS.homePage.loginModal.validation.password.valid),
});

export const AssignGlpinValidation = Yup.object().shape({
  glpin: Yup.string().required(
    STRINGS.productDetails.assignGlpin.validation.glpin.required
  ),
  productName: Yup.string().required(
    STRINGS.productDetails.assignGlpin.validation.productName.required
  ),
  brandName: Yup.string().required(
    STRINGS.productDetails.assignGlpin.validation.brandName.required
  ),
  upc: Yup.string().required(
    STRINGS.productDetails.assignGlpin.validation.upc.required
  ),
  size: Yup.string().required(
    STRINGS.productDetails.assignGlpin.validation.size.required
  ),
});

export const ProductInformationValidation = Yup.object().shape({
  brandName: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.brandName.required
  ),
  productName: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.productName.required
  ),
  systemDept: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.systemDept.required
  ),
  priceAvg: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.priceAvg.required
  ),
  systemCategory: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.systemCategory.required
  ),
  productDescriptionShort: Yup.string().required(
    STRINGS.productDetails.productInformation.validation.productDescriptionShort
      .required
  ),
  // ingredients: Yup.string().required(
  //   STRINGS.productDetails.productInformation.validation.ingredients.required
  // ),
});

export const registerModalValidation = Yup.object().shape({
  email: Yup.string()
    .email(STRINGS.homePage.registerModal.validation.email.valid)
    .required(STRINGS.homePage.registerModal.validation.email.required),
  password: Yup.string()
    .required(STRINGS.homePage.registerModal.validation.password.required)
    .min(8, STRINGS.homePage.registerModal.validation.password.valid),
  firstName: Yup.string().required(
    STRINGS.homePage.registerModal.validation.firstName.required
  ),
  lastName: Yup.string().required(
    STRINGS.homePage.registerModal.validation.lastName.required
  ),
  zipCode: Yup.string().required(
    STRINGS.homePage.registerModal.validation.zipCode.required
  ),
  termsid: Yup.bool().oneOf([true], "Field must be checked"),
});

export const ForgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .email(STRINGS.homePage.loginModal.validation.email.valid)
    .required(STRINGS.homePage.loginModal.validation.email.valid),
});
