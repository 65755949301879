import * as authReducer from "./authReducer";
import * as productDetailsReducer from "./productDetailsReducer";
import * as initialInventory from "./initialInventory";
import * as loadingReducer from "./loadingReducer";

export default Object.assign(
  {},
  authReducer,
  productDetailsReducer,
  initialInventory,
  loadingReducer
);
