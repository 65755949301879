import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Api } from "../../../config";
import moment from "moment";
import "../../assets/custom.css";
import ContractCellRender from "./ContractCellRender";
import ActionCellRender from "./ActionCellRender";
import "../Products.scss";
import GlpSearch from "./../../../Gcomponents/GlpSearch/GlpSearch";
import client from "../../../api/client";

const GridTable = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [filterInputValue, setFilterInputValue] = useState("");

  useEffect(() => {
    gridApi?.setServerSideDatasource(fetchInitialInventory());
  }, [filterInputValue]);

  const filterInitialInventory = (e) => {
    setFilterInputValue(e.target.value);
  };

  const formatResponse = (product) => {
    return {
      upc: product.upc,
      brandName: product?.GlpProduct?.brandName || null,
      productName: product?.GlpProduct?.productName,
      size: product?.GlpProduct?.size,
      contractId: product.contractId,
      glpin: product?.GlpProduct?.glpin,
      updatedAt: moment(product?.updatedAt).format("LL"),
      addedBy: product?.addedBy,
      actions: "",
      id: product.id,
    };
  };

  const fetchInitialInventory = () => {
    return {
      getRows: (params) => {
        const config = {
          params: {
            limit: perPage,
            searching_data: filterInputValue,
            page: params.api.paginationGetCurrentPage() + 1,
          },
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ac_expire")}`,
          // },
        };

        // add sortBy in request
        if (params.request.sortModel.length > 0) {
          config.params.sortBy =
            params.request.sortModel[0]?.colId +
            ":" +
            params.request.sortModel[0]?.sort;
        }

        if (filterInputValue === "") {
          delete config.params.searching_data;
        }
        client
          .get(`${Api.INITIAL_INVENTORIES_PRODUCT}`, config)
          .then((response) => {
            const { results: rc, totalResults: trc } = response.data;
            params.successCallback(
              rc.map((record) => formatResponse(record)),
              trc
            );
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(fetchInitialInventory());
  };

  return (
    <>
      <GlpSearch
        data={[]}
        mode="button"
        onInput={filterInitialInventory}
        placeholder="Search by Product name, UPC, Contract ID, glpin"
        searchButton="Search"
        value={filterInputValue}
      />
      <div className="ag-theme-alpine" style={{ height: "70vh" }}>
        <AgGridReact
          defaultColDef={{ flex: 1, minWidth: 100 }}
          pagination={true}
          rowSelection="multiple"
          autoGroupColumnDef={{
            minWidth: 140,
          }}
          rowModelType="serverSide"
          serverSideStoreType={"partial"}
          animateRows={true}
          alwaysShowVerticalScroll={false}
          cacheBlockSize={perPage}
          paginationPageSize={perPage}
          onGridReady={onGridReady}
          frameworkComponents={{
            actionCellRender: ActionCellRender,
            contractCellRender: ContractCellRender,
          }}
        >
          {columnDefinition.map((attributes, i) => (
            <AgGridColumn {...attributes} key={attributes.field} />
          ))}
        </AgGridReact>
      </div>
    </>
  );
};

// cell styles
const cellStyles = {
  fontSize: "12px",
  border: "none !important",
  outline: "none",
};

// Column definition of the table
const columnDefinition = [
  {
    headerName: "UPC",
    field: "upc",
    minWidth: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    sortable: true,
    cellStyle: {
      color: "#F67A1A",
      ...cellStyles,
    },
    className: "upc",
  },
  {
    headerName: "Brand",
    minWidth: 100,
    field: "brandName",
    sortable: true,
    cellStyle: {
      color: "#6D838B",
      ...cellStyles,
    },
    cellClass: "brand",
  },
  {
    headerName: "Product name",
    field: "productName",
    minWidth: 145,
    sortable: true,
    cellStyle: {
      color: "#248937",
      ...cellStyles,
    },
    cellClass: "product-name",
  },
  {
    headerName: "Product size",
    field: "size",
    width: 80,
    sortable: true,
    cellStyle: {
      color: "#6D838B",
      ...cellStyles,
    },
    cellClass: "size",
  },
  {
    headerName: "Contract ID",
    field: "contractId",
    minWidth: 115,
    sortable: true,
    cellStyle: {
      ...cellStyles,
    },
    cellClass: "contract-id",
    cellRenderer: "contractCellRender",
  },
  {
    headerName: "GlpPin",
    sortable: true,
    field: "glpin",
    minWidth: 80,
    cellStyle: {
      color: "#6D838B",
      ...cellStyles,
    },
    cellClass: "glpin",
  },
  {
    headerName: "Last Mod. Date",
    field: "updatedAt",
    minWidth: 135,
    sortable: true,
    cellStyle: {
      color: "#F67A1A",
      ...cellStyles,
    },
    cellClass: "last-modified-date",
  },
  {
    headerName: "Last Mod. Id",
    field: "addedBy",
    minWidth: 100,
    sortable: true,
    cellStyle: {
      color: "#F67A1A",
      ...cellStyles,
    },
    cellClass: "last-modified-id",
  },
  {
    headerName: "Actions",
    field: "",
    minWidth: 90,
    sortable: false,
    cellStyle: {
      border: "none !important",
      outline: "none",
      marginLeft: "20px",
    },
    cellRenderer: "actionCellRender",
  },
];

export default GridTable;
