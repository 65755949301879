import { Nav } from "react-bootstrap";
import { scroller } from "react-scroll";

export const CenterView = () => {
  return (
    <Nav className="mr-auto glp-header__login">
      <Nav.Link
        onClick={() =>
          scroller.scrollTo("home", { smooth: true, duration: 500 })
        }
      >
        Home
      </Nav.Link>
      <Nav.Link
        onClick={() =>
          scroller.scrollTo("features", { smooth: true, duration: 500 })
        }
      >
        Features
      </Nav.Link>
      <Nav.Link
        onClick={() =>
          scroller.scrollTo("works", { smooth: true, duration: 500 })
        }
      >
        How It works
      </Nav.Link>
      <Nav.Link
        onClick={() =>
          scroller.scrollTo("mobile", { smooth: true, duration: 500 })
        }
      >
        Mobile App
      </Nav.Link>
      <Nav.Link
        onClick={() =>
          scroller.scrollTo("pricing", { smooth: true, duration: 500 })
        }
      >
        Pricing
      </Nav.Link>
    </Nav>
  );
};
