import React from "react";
import "./glp-footer.scss";
import { ReactComponent as SocialLink } from "./img/social-link.svg";
import { ReactComponent as ArrowUp } from "./img/arrow-up.svg";
import PropTypes from "prop-types";

GlpFooter.propTypes = {
  logo: PropTypes.element,
  items: PropTypes.func,
  copyright: PropTypes.string,
  newsletter: PropTypes.element,
  language: PropTypes.func,
};

export default function GlpFooter({
  logo,
  copyright,
  items,
  newsletter,
  language,
  ...props
}) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="glp-footer">
      <div className="container">
        <div className="glp-footer--body">
          <div className="glp-footer-item">
            <div className="flex">{logo}</div>
            <p className="text-grey">{copyright}</p>
          </div>
          <div className="glp-footer-item">{items()}</div>
          <div className="glp-footer-item">{newsletter}</div>
          <div className="glp-footer-item">
            <div className="heading">
              <h5>Language</h5>
            </div>
            <div className="content">{language()}</div>
          </div>
          <div className="glp-footer-item">
            <div className="heading">
              <h5>Follow Us</h5>
            </div>
            <div className="content">
              <SocialLink />
            </div>
          </div>
        </div>
      </div>
      <a className="arrow-up">
        <div onClick={scrollToTop} className="flex">
          <span>Go to top</span>
          <ArrowUp />
        </div>
      </a>
    </div>
  );
}
