export const SET_DATA_COLLECTION = "SET_DATA_COLLECTION";

export const FETCH_RECENTLY_ADDED = "FETCH_RECENTLY_ADDED";
export const FETCH_RECENTLY_ADDED_SUCCESS = "FETCH_RECENTLY_ADDED_SUCCESS";
export const FETCH_RECENTLY_ADDED_FAILED = "FETCH_RECENTLY_ADDED_FAILED";
export const SET_PRODUCTS_FOR_EDITING = 'SET_PRODUCTS_FOR_EDITING';

export const FETCH_INITIAL_INVENTORY = "FETCH_INITIAL_INVENTORY";
export const FETCH_INITIAL_INVENTORY_SUCCESS =
  "FETCH_INITIAL_INVENTORY_SUCCESS";
export const FETCH_INITIAL_INVENTORY_FAILED = "FETCH_INITIAL_INVENTORY_FAILED";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const RESET_PRODUCTS = "RESET_PRODUCTS";

export const CHANGE_ADD_TYPE = "CHANGE_ADD_TYPE";

export const POST_INVENTORY = "POST_INVENTORY";
export const POST_INVENTORY_SUCCESS = "POST_INVENTORY_SUCCESS";
export const POST_INVENTORY_FAILED = "POST_INVENTORY_FAILED";

export const SET_UPC = "SET_UPC";
export const SET_ERROR = "SET_ERROR";

export const FETCH_GLP_PRODUCT = "FETCH_GLP_PRODUCT";
export const RECEIVE_INITIAL_INVENTORY = "RECEIVE_INITIAL_INVENTORY";
export const RECEIVE_INITIAL_INVENTORY_FAILED =
  "RECEIVE_INITIAL_INVENTORY_FAILED";
export const RECEIVE_GLP_PRODUCT = "RECEIVE_GLP_PRODUCT";
export const RECEIVE_GLP_PRODUCT_FAILED = "RECEIVE_GLP_PRODUCT_FAILED";

export const RECEIVE_PRODUCT_TO_ADD = "RECEIVE_PRODUCT_TO_ADD";

export const ADD_NEXT = "ADD_NEXT";
export const SET_PRODUCT_SEARCH_QUERY = 'SET_PRODUCT_SEARCH_QUERY'
