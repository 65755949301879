import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./glp-button.scss";

function GlpButton({
  label,
  variant,
  size,
  disabled,
  color,
  type,
  icon,
  onClick,
  ...props
}) {
  const buttonProp = {};
  buttonProp["aria-label"] = label;

  const className = classNames({
    "glp-button": true,
    "glp-button--sm": size === "sm",
    "glp-button--md": size === "md",
    "glp-button--lg": size === "lg",
    "glp-button--disabled": disabled,
    [`glp-button-${variant}--danger`]: color === "danger",
    [`glp-button-${variant}--info`]: color === "info",
    [`glp-button-${variant}--primary`]: color === "primary",
    [`glp-button-${variant}--secondary`]: color === "secondary",
  });

  if (disabled) {
    buttonProp["aria-disabled"] = disabled;
  }

  return (
    <button
      className={className}
      type={type ? type : "button"}
      onClick={onClick}
      {...props}
      {...buttonProp}
    >
      <span className="glp-button--label">{label}</span>
      {icon && <i className="glp-button--icon">{icon()}</i>}
    </button>
  );
}

GlpButton.defaultProps = {
  size: "sm",
  type: "button",
  color: "primary",
  variant: "fill",
  onClick: () => {},
};

GlpButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["outline", "fill"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  color: PropTypes.oneOf(["primary", "secondary", "danger", "info"]),
};

export default GlpButton;
