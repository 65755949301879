import * as types from "./types";
import { loginUser, logoutUser } from "../../../api/methods";

export const requestEmployeeLogin = (
  username,
  password,
  routerHistory,
  setModalShow
) => {
  return async (dispatch) => {
    dispatch(requestLogin(username, password));
    await loginUser(username, password)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loginSuccess(res.data));
          setModalShow(false);
          localStorage.setItem("ac_expire", res.data.tokens.access.token);
          routerHistory.push("/product-management");
        } else {
          dispatch(loginFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(loginFailed(err.message));
      });
  };
};

export const requestEmployeeLogout = (history) => {
  return async (dispatch, getState) => {
    const token = getState().authReducer.tokens.refresh.token;
    await logoutUser(token)
      .then((res) => {
        if (res.status === 204) {
          dispatch(requestLogout());
          history.push("/");
        } else {
          dispatch(logoutFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(logoutFailed(err.message));
      });
  };
};

export function requestLogin(username, password) {
  return {
    type: types.LOGIN_REQUEST,
    username,
    password,
  };
}

export function loginSuccess(response) {
  return {
    type: types.LOGIN_SUCCESS,
    employee: response.employee,
    tokens: response.tokens,
  };
}

export function loginFailed(error) {
  return {
    type: types.LOGIN_FAILED,
    message: error,
  };
}

export function resetErrors() {
  return {
    type: types.RESET_ERRORS,
  };
}

export function requestLogout() {
  return {
    type: types.LOG_OUT,
  };
}

export function logoutFailed(error) {
  return {
    type: types.LOGOUT_FAILED,
    message: error,
  };
}

export function refreshTokens(response) {
  return {
    type: types.REFRESH_TOKENS,
    tokens: response,
  };
}

export { types };
