import React from 'react';
import PropTypes from 'prop-types';
import './glp-feat-callout.scss';

export default function GlpFeatureCallout({
  title,
  description,
  children,
  ...props
}) {
  return (
    <div className="glp-feat-callout mt-5 mt-lg-0">
      <div className="container">
        <div className="glp-feat-callout--header">
          <h1 className="glp-feat-callout-heading">{title}</h1>
          <span className="glp-feat-callout-headline">{description}</span>
        </div>
        <div className="glp-feat-callout--body">{children}</div>
      </div>
    </div>
  );
}

export function GlpFeatureCalloutItem({
  title,
  description,
  icon,
  width,
  ...props
}) {
  const styles = {
    ...(width && { width: `${width}em` }),
  };
  return (
    <div className="glp-feat-callout-item" style={styles}>
      {icon && <div className="glp-feat-callout-item--circle">{icon}</div>}
      <p className="glp-feat-callout-item--title">{title}</p>
      <div className="glp-feat-callout-item--content">
        <p className="glp-feat-callout-item--description">{description}</p>
      </div>
    </div>
  );
}

GlpFeatureCalloutItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

GlpFeatureCallout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
