import React from 'react';
import PropTypes from 'prop-types';
import './glp-callout.scss';

import { ReactComponent as Apple } from './img/applestore.svg';
import { ReactComponent as Google } from './img/playstore.svg';

import { ReactComponent as Grocery } from './img/grocery.svg';
import { ReactComponent as Recipes } from './img/recipeshistory.svg';

const AppStoreIcon = () => <Apple />;
const PlayStoreIcon = () => <Google />;
const frontImage = () => <Grocery />;
const backImage = () => <Recipes />;

GlpCallout.propTypes = {
  frontImage: PropTypes.func,
  backImage: PropTypes.func,
  topContent: PropTypes.string,
  highlightContent: PropTypes.string,
  description: PropTypes.string,
  appStoreBtn: PropTypes.func,
  playStoreBtn: PropTypes.func,
};

GlpCallout.defaultProps = {
  frontImage: frontImage,
  backImage: backImage,
  topContent: 'GET GROCERY LIST PLUS INTO',
  highlightContent: 'YOUR POCKET',
  description:
    'Nibh lorem gravida sed auctor congue. Nisi in tincidunt in gravida. Molestie massa dolor turpis et.',
  appStoreBtn: AppStoreIcon,
  playStoreBtn: PlayStoreIcon,
};

function GlpCallout({
  frontImage,
  backImage,
  topContent,
  highlightContent,
  description,
  appStoreBtn,
  playStoreBtn,
}) {
  return (
    <div className="container">
      <div className="glp-callout">
        <div className="glp-callout__left">
          <div className="glp-callout__left__section">
            {backImage && (
              <div className="glp-callout__left__front-image">
                {backImage()}
              </div>
            )}
            {frontImage && (
              <div className="glp-callout__left__back-image">
                {frontImage()}
              </div>
            )}
          </div>
        </div>

        <div className="glp-callout__right">
          <div className="glp-callout__right__top">
            <div className="glp-callout__right__top-content">{topContent}</div>
            <div className="glp-callout__right__highlight-content">
              {highlightContent}
            </div>
          </div>
          <div className="glp-callout__right__description">{description}</div>
          <div className="glp-callout__right__buttons">
            <div className="glp-callout__buttons__app-store">
              {appStoreBtn()}
            </div>
            <div className="glp-callout__buttons__play-store">
              {playStoreBtn()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlpCallout;
