import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

function MainTabs(props) {
  const [key, setKey] = useState(props.items.key);
  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="prod-admin"
      >
        {props.items.map(function (item, index) {
          return (
            <Tab eventKey={item.key} title={item.title} key={`tabs__${index}`}>
              <div className="glp-profile-content product-inventory">
                {item.component}
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
}

export default MainTabs;
