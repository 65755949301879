import client from "./client";
import { Api } from "../config";

export async function loginUser(username, password) {
  try {
    let res = await client.post(Api.LOGIN, {
      username,
      password,
    });
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
    // throw handler(e);
  }
}

export async function logoutUser(token) {
  try {
    let res = await client.post(Api.LOGOUT, {
      refreshToken: token,
    });
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
    // throw handler(e);
  }
}

export async function registerUser(data) {
  try {
    let res = await client.post(Api.REGISTER, data);
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

export async function forgotPassword(data) {
  try {
    let res = await client.post(Api.FORGET_PASSWORD, data);
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

function handler(err) {
  let error = err;
  if (err.response && err.response.data.hasOwnProperty("message")) {
    error = err.response.data;
  } else if (!err.hasOwnProperty("message")) {
    error = err.toJSON();
  }
  return error;
  // return new Error(error.message);
}

// single inventory
export async function getSingleInventory(id) {
  try {
    let res = await client.get(Api.SINGLE_INVENTORY + "/" + id);
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

// initial inventory
export async function updateSingleInventory(data, id) {
  try {
    let res = await client.patch(Api.UPDATE_SINGLE_INVENTORY + "/" + id, data);
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

export async function updateSingleInventoryNutrition(data, id) {
  try {
    let res = await client.patch(
      Api.UPDATE_SINGLE_INVENTORY + "/" + id + "/nutrition",
      data
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

export async function getInitialInventories(
  page,
  limit = 10,
  contractId,
  upc,
  glpin,
  addedBy,
  sortBy
) {
  try {
    let params = {
      limit,
      page,
    };

    if (contractId) {
      params.contractId = contractId;
    }
    if (upc) {
      params.upc = upc;
    }
    if (glpin) {
      params.glpin = glpin;
    }
    if (addedBy) {
      params.addedBy = addedBy;
    }

    let res = await client.get(Api.INITIAL_INVENTORIES_PRODUCT, { params });
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

// main image updation

export async function updateMainImage(id, formData) {
  try {
    let res = await client.patch(
      Api.GLP_PRODUCTS + id + "/main-image",
      formData
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

// product thumbnail updation

export async function updateProductThumbnail(id, formData) {
  try {
    let res = await client.patch(
      Api.GLP_PRODUCTS + id + "/thumbnail-image",
      formData
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

// add extra images for product

export async function addOtherImagesForProduct(id, formData) {
  try {
    let res = await client.patch(
      Api.GLP_PRODUCTS + id + "/other-images",
      formData
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
}

// generate gl pin

export const generateGlPin = async () => {
  try {
    let res = await client.post(Api.GENERATE_GLPIN);
    return { status: res.status, data: res.data };
  } catch (e) {
    return handler(e);
  }
};
