import React, { useState, useRef } from "react";
import GlpInput from "./../../../Gcomponents/GlpInput/GlpInput";
import GlpButton from "./../../../Gcomponents/GlpButton/GlpButton";
import { useDispatch, useSelector } from "react-redux";
import addImg from "../../assets/images/add-img.png";
import editImg from "../../assets/images/edit-icon.svg";
import {
  requestAddOtherImagesForProduct,
  requestMainImageUpdate,
  requestProductThumbnailUpdate,
} from "../../../store/actions/ProductDetails";
import { Modal, Button } from "react-bootstrap";
import "./ImagesUpdate.css";
import { useHistory } from "react-router";
import GlpLoader from "../../GlpLoader/GlpLoader";

function ImagesUpdate(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const singleInventoryData = useSelector(
    (state) => state.productDetailsReducer.singleInventoryData
  );

  const initialInventoryData = useSelector((state) => state.initialInventory);
  const [formData, setFormData] = useState(new FormData());

  const [extraImagesFormData, setExtraImagesFormData] = useState(
    new FormData()
  );

  const [modalShow, setModalShow] = React.useState(false);

  const [imageToDisplayInModal, setImageToDisplayInModal] = useState(
    "https://via.placeholder.com/1000"
  );

  // let [thumbnailImg, setThumbnailImg] = useState(
  //   singleInventoryData?.product?.image_thumbnail ||
  //     process.env.REACT_APP_S3_URL +
  //       singleInventoryData?.product?.glp_image_thumbnail ||
  //     null
  // );

  let [thumbnailImg, setThumbnailImg] = useState(
    singleInventoryData?.GlpProduct?.ProductImages.filter(
      (image) => image?.imageType === "thumbnail"
    ).pop().image || null
  );

  // let [mainImg, setMainImg] = useState(
  //   singleInventoryData?.product?.image_main ||
  //     process.env.REACT_APP_S3_URL +
  //       singleInventoryData?.product?.glp_image_large ||
  //     null
  // );

  let [mainImg, setMainImg] = useState(
    singleInventoryData?.GlpProduct?.ProductImages.filter(
      (image) => image?.imageType === "main"
    ).pop().image || null
  );

  React.useEffect(() => {
    setThumbnailImg(
      singleInventoryData?.GlpProduct?.ProductImages.filter(
        (image) => image?.imageType === "thumbnail"
      )[0].image || null
    );
  }, [singleInventoryData]);

  React.useEffect(() => {
    setMainImg(
      singleInventoryData?.GlpProduct?.ProductImages.filter(
        (image) => image?.imageType === "main"
      )[0].image || null
    );
  }, [singleInventoryData]);

  React.useEffect(() => {
    // let tempGlpImages = singleInventoryData?.product?.glp_images.map(
    //   (image) => process.env.REACT_APP_S3_URL + image?.imageString
    // );
    if (singleInventoryData?.GlpProduct?.ProductImages) {
      setExtraImages([
        ...singleInventoryData?.GlpProduct?.ProductImages?.filter(
          (img) => img.imageType === "other"
        ).map((img) => img.image),
      ]);
    }
  }, [singleInventoryData]);

  const glpProductId = props.glpProductId;
  const uploadBtnRef = useRef();
  const uploadMainImgBtnRef = useRef();
  const uploadThumbnailBtnRef = useRef();
  const thumbnailImageRef = useRef();
  const mainImageRef = useRef();

  let glpImages = singleInventoryData?.product?.glp_images.map(
    (image) => process.env.REACT_APP_S3_URL + image?.imageString
  );

  // const [extraImages, setExtraImages] = useState([
  //   ...singleInventoryData?.product?.images,
  //   ...glpImages,
  // ]);

  const [extraImages, setExtraImages] = useState([
    singleInventoryData?.GlpProduct?.ProductImages?.filter(
      (image) => image.imageType === "other"
    ).map((img) => img.image),
  ]);

  // React.useEffect(() => {
  //   let tempGlpImages = singleInventoryData?.product?.glp_images.map(
  //     (image) => process.env.REACT_APP_S3_URL + image?.imageString
  //   );
  //   setExtraImages([...singleInventoryData?.product?.images, ...tempGlpImages]);
  // }, [singleInventoryData]);

  const imgStyle = {
    width: "auto",
    height: "120px",
    borderRadius: "10px",
  };
  const cardImgStyle = {
    height: "120px",
    width: "120px",
    position: "relative",
  };
  const editIconStyle = {
    height: "15px",
    width: "auto",
    cursor: "pointer",
    top: 4,
    right: 4,
  };
  const productsIdsForEditing =
    initialInventoryData.selectedProductsForEditing.ids;
  // const [currentProductId, setCurrentProductId] = useState(props.selectedProductItemId);
  let currentProductId = props.selectedProductItemId;
  // console.log(productsIdsForEditing, 'productsIdsForEditing', currentProductId, 'currnetProductId--------------')

  const handleSwitchingNextProduct = () => {
    const currentProductIdIndex = productsIdsForEditing.findIndex(
      (id) => id == currentProductId
    );

    if (currentProductIdIndex >= productsIdsForEditing.length - 1) {
      history.push("product-management");
    } else {
      // setCurrentProductId(productsIdsForEditing[currentProductId + 1])
      currentProductId = productsIdsForEditing[currentProductIdIndex + 1];
      console.log(currentProductId, "currentProductId");
      props.setSelectedProductItemId(currentProductId);
      props.setKey("glpin");
    }
  };
  const handleMainImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      setMainImg(e.target.result);
      mainImageRef.current.src = e.target.result;
      setFormData(new FormData());
      formData.append("id", glpProductId);
      formData.append("main_image", file);
      dispatch(requestMainImageUpdate(glpProductId, formData));
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      setThumbnailImg(e.target.result);
      thumbnailImageRef.current.src = e.target.result;
      setFormData(new FormData());
      formData.append("id", glpProductId);
      formData.append("thumbnail_image", file);
      dispatch(requestProductThumbnailUpdate(glpProductId, formData));
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.onload = (e) => {
        res(reader.result);
      };
      reader.readAsDataURL(image);
    });
  };

  const getFormattedImage = (img) => {
    const image = "" + img;
    return image?.split("://")?.includes("https") ||
      image.split("/").includes("data:image")
      ? image
      : process.env.REACT_APP_BUCKET_URL + image;
  };

  const handleAddExtraImages = async (e) => {
    const files = [...e.target.files];
    if (!files.length) return;
    const filesPromises = files.map((file) => fileToDataUri(file));
    const allNewUploadedImages = await Promise.all(filesPromises);
    setExtraImagesFormData(new FormData());
    setExtraImages([...extraImages, ...allNewUploadedImages]);
    extraImagesFormData.append("id", glpProductId);
    files.forEach((file) => extraImagesFormData.append("other_images", file));
    dispatch(
      requestAddOtherImagesForProduct(glpProductId, extraImagesFormData)
    );
  };
  const handleMainImageClick = (e) => {
    if (mainImg) {
      setImageToDisplayInModal(e.target.src);
      setModalShow(true);
    } else {
      uploadMainImgBtnRef.current.click();
    }
  };
  const handleThumbnailClick = (e) => {
    if (thumbnailImg) {
      setImageToDisplayInModal(e.target.src);
      setModalShow(true);
    } else {
      uploadThumbnailBtnRef.current.click();
    }
  };
  const handleExtraImagesClick = (e) => {
    setImageToDisplayInModal(e.target.src);
    setModalShow(true);
  };

  return (
    <>
      <div className="image-section">
        <div className="row">
          <div className="col-md-11">
            <div className="d-flex mt-3">
              <div>
                <h4 className="productImageHeadings">Main Image</h4>
                <div className="main-image image-card text-center mr-4">
                  <div className="card-image">
                    <div className="image" style={cardImgStyle}>
                      <img
                        ref={mainImageRef}
                        src={mainImg ? mainImg : addImg}
                        style={imgStyle}
                        onClick={handleMainImageClick}
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        ref={uploadMainImgBtnRef}
                        className="d-none"
                        onChange={handleMainImageUpload}
                      />
                      {mainImg ? (
                        <img
                          src={editImg}
                          alt="edit-icon"
                          className="position-absolute d-none"
                          style={editIconStyle}
                          onClick={() => uploadMainImgBtnRef.current.click()}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h4 className="productImageHeadings">Thumbnail</h4>
                <div className="thumbnail-image image-card text-center mb-3 mr-3">
                  <div className="card-image">
                    <div className="image" style={cardImgStyle}>
                      <img
                        ref={thumbnailImageRef}
                        src={thumbnailImg ? thumbnailImg : addImg}
                        style={imgStyle}
                        onClick={handleThumbnailClick}
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        ref={uploadThumbnailBtnRef}
                        className="d-none"
                        onChange={handleThumbnailUpload}
                      />
                      {thumbnailImg ? (
                        <img
                          src={editImg}
                          alt="edit-icon"
                          className="position-absolute d-none"
                          style={editIconStyle}
                          onClick={() => uploadThumbnailBtnRef.current.click()}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="productImageHeadings">Other Images</h4>
            <div className="d-flex flex-wrap">
              {extraImages?.map((image, index) => {
                return (
                  <div
                    className="image-card text-center mb-4 mr-4"
                    key={`image-card__id_${index}`}
                  >
                    <div className="card-image">
                      <div className="image" style={cardImgStyle}>
                        <img
                          src={getFormattedImage(image)}
                          style={imgStyle}
                          className="img-fluid cursor-pointer"
                          onClick={handleExtraImagesClick}
                          alt="glp extras"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="image-card text-center">
                <div className="card-image">
                  <div className="image">
                    <img
                      src={addImg}
                      className="cursor-pointer"
                      alt="add-img-btn"
                      style={imgStyle}
                      onClick={() => uploadBtnRef.current.click()}
                    />
                    <input
                      type="file"
                      className="d-none"
                      ref={uploadBtnRef}
                      onChange={handleAddExtraImages}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-11 text-right">
              <div className="mt-4 pt-5 d-flex justify-content-end">
                <div>
                  <GlpButton
                    color="secondary"
                    label="SAVE AND EXIT"
                    onClick={() => {
                      history.push("/product-management");
                    }}
                    variant="outline"
                  />
                </div>
                <div className="ml-3">
                  <GlpButton
                    label="Save And Continue"
                    onClick={handleSwitchingNextProduct}
                    variant="outline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        displayImg={imageToDisplayInModal}
      />
      {loadingReducer.isLoading && <GlpLoader />}
    </>
  );
}
export default ImagesUpdate;
function ImageModal({ show, onHide, displayImg }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <img src={displayImg} alt="product-image" className="img-fluid" />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
