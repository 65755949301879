import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "../img/delete.svg";
import { ReactComponent as EditIcon } from "../img/edit.svg";
import { ReactComponent as PreviewIcon } from "../img/preview.svg";
import DeleteModal from "../../Modal/DeleteModal";
import { setProductsForEditing } from "../../../store/actions/InitialInventory/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ActionCellRender = (props) => {
  const dispatch = useDispatch();
  const gridApi = props.api;
  const history = useHistory();
  const selectedRowId = props.data.id;

  const [deleteModal, setDeleteModel] = useState(false);

  const handleEditProduct = () => {
    let selectedNodes = gridApi?.getSelectedNodes();
    let selectedDataIds = selectedNodes?.map((node) => node.data.id) || [];

    if (!selectedDataIds.length) {
      let selectedProduct = { rowData: [props.data], ids: [selectedRowId] };
      dispatch(setProductsForEditing(selectedProduct));
    } else {
      let selectedProducts = {
        rowData: selectedNodes?.map((node) => node.data),
        ids: selectedDataIds,
      };
      dispatch(setProductsForEditing(selectedProducts));
    }
    history.push(`/product-details/${selectedDataIds[0]}`);
  };

  const handleDeleteProduct = () => {
    setDeleteModel(true);
  };

  return (
    <React.Fragment>
      <div className="row align-center">
        <div className="d-flex align-bottom">
          <EditIcon
            className="mr-2 cursor-pointer"
            onClick={handleEditProduct}
          />
          <PreviewIcon className="mx-1 cursor-pointer" />
          <DeleteIcon
            className="mx-2 cursor-pointer"
            onClick={handleDeleteProduct}
          />
        </div>
      </div>
      <DeleteModal show={deleteModal} onHide={() => setDeleteModel(false)} />
    </React.Fragment>
  );
};

export default ActionCellRender;
