import React, { Component } from "react";
import GlpRecipeNutrition from "./../../Gcomponents/GlpRecipeNutrition/GlpRecipeNutrition";
import GlpRecipeIngredient from "./../../Gcomponents/GlpRecipeIngredient/GlpRecipeIngredient";
import GlpRecipeDirection from "./../../Gcomponents/GlpRecipeDirection/GlpRecipeDirection";
import GlpRecipe from "./../../Gcomponents/GlpRecipeOfDay/GlpRecipe";

import {
  nutrition,
  ingredient,
  direction,
} from "./../../Gcomponents/GlpRecipeOfDay/data/recipe";

const RecipeDetails = {
  name: "Ginger Cookies",
  price: "$38.95",
  prepareTime: "2h 30m",
  nutritionComponent: <GlpRecipeNutrition nutrition={nutrition} />,
  ingredientComponent: <GlpRecipeIngredient ingredient={ingredient} />,
  directionComponent: <GlpRecipeDirection direction={direction} />,
};

class Recipe extends Component {
  render() {
    return <GlpRecipe title="Recipe of the Day" recipe={RecipeDetails} />;
  }
}

export default Recipe;
