import * as types from "./types";
import * as api from "../../../api/methods";
import { disableLoading, enableLoading } from "../Loading";

export function getSingleInventory(id) {
  return {
    type: types.GET_SINGLE_INVENTORY,
    id,
  };
}

export function singleInventorySuccess(response) {
  return {
    type: types.SINGLE_INVENTORY_SUCCESSS,
    payload: response,
  };
}

export function inventoryFailed(error) {
  return {
    type: types.SINGLE_INVENTORY_FAILED,
    message: error,
  };
}

export function updateInventorySuccess(response) {
  return {
    type: types.UPDATE_INVENTORY_SUCCESS,
    payload: response,
  };
}

export function updateInventoryFailed(error) {
  return {
    type: types.UPDATE_INVENTORY_FAILED,
    message: error,
  };
}

export function updateSingleInventory(data, id) {
  return {
    type: types.UPDATE_SINGLE_INVENTORY,
    id,
    data,
  };
}

export function updateInventoryNutritionSuccess(response) {
  return {
    type: types.UPDATE_INVENTORY_NUTRITION_SUCCESS,
    payload: response,
  };
}

export function updateInventoryNutritionFailed(error) {
  return {
    type: types.UPDATE_INVENTORY_NUTRITION_FAILED,
    message: error,
  };
}

export function updateSingleInventoryNutrition(data, id) {
  return {
    type: types.UPDATE_SINGLE_INVENTORY_NUTRITION,
    id,
    data,
  };
}

export const requestSingleInventory = (id) => {
  return async (dispatch) => {
    dispatch(getSingleInventory(id));
    dispatch(enableLoading());
    await api
      .getSingleInventory(id)
      .then((res) => {
        dispatch(disableLoading());
        if (res.status === 200) {
          dispatch(singleInventorySuccess(res.data));
          // routerHistory.push('/product-details')
        } else {
          dispatch(inventoryFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(disableLoading());
        dispatch(inventoryFailed(err.message));
      });
  };
};

// product images update

export function updateMainImage() {
  return {
    type: types.UPDATE_MAIN_IMAGE,
  };
}

export function updateMainImageSuccess(response) {
  return {
    type: types.UPDATE_MAIN_IMAGE_SUCCESS,
    payload: response,
  };
}

export function updateMainImageFailed(error) {
  return {
    type: types.UPDATE_MAIN_IMAGE_FAILED,
    message: error,
  };
}

export const requestMainImageUpdate = (id, formData) => {
  return async (dispatch) => {
    dispatch(updateMainImage());
    await api
      .updateMainImage(id, formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateMainImageSuccess(res.data));
        } else {
          dispatch(updateMainImageFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(updateMainImageFailed(err.message));
      });
  };
};

// product thumbnail update

export function updateProductThumbnail(id) {
  return {
    type: types.UPDATE_PRODUCT_THUMBNAIL,
  };
}

export function updateProductThumbnailSuccess(response) {
  return {
    type: types.UPDATE_PRODUCT_THUMBNAIL_SUCCESS,
    payload: response,
  };
}

export function updateProductThumbnailFailed(error) {
  return {
    type: types.UPDATE_PRODUCT_THUMBNAIL_FAILED,
    message: error,
  };
}

export const requestProductThumbnailUpdate = (id, formData) => {
  return async (dispatch) => {
    dispatch(updateProductThumbnail());
    await api
      .updateProductThumbnail(id, formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateProductThumbnailSuccess(res.data));
        } else {
          dispatch(updateProductThumbnailFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(updateProductThumbnailFailed(err.message));
      });
  };
};

// add product other images

export function addOtherImagesForProduct() {
  return {
    type: types.ADD_OTHER_IMAGES_FOR_PRODUCT,
  };
}

export function addOtherImagesForProductSuccess(response) {
  return {
    type: types.ADD_OTHER_IMAGES_FOR_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function addOtherImagesForProductFailed(error) {
  return {
    type: types.ADD_OTHER_IMAGES_FOR_PRODUCT_FAILED,
    message: error,
  };
}

export const requestAddOtherImagesForProduct = (id, formData) => {
  return async (dispatch) => {
    dispatch(addOtherImagesForProduct());
    dispatch(enableLoading());
    await api
      .addOtherImagesForProduct(id, formData)
      .then((res) => {
        dispatch(disableLoading());
        if (res.status === 200) {
          dispatch(addOtherImagesForProductSuccess(res.data));
        } else {
          dispatch(addOtherImagesForProductFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(disableLoading());
        dispatch(addOtherImagesForProductFailed(err.message));
      });
  };
};

// update single inventory

export const updateSingleInventoryProduct = (data, id) => {
  return async (dispatch) => {
    dispatch(updateSingleInventory(data, id));
    dispatch(enableLoading());
    await api
      .updateSingleInventory(data, id)
      .then((res) => {
        dispatch(disableLoading());
        if (res.status === 200) {
          dispatch(updateInventorySuccess(res.data));
        } else {
          dispatch(updateInventoryFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(disableLoading());
        dispatch(updateInventoryFailed(err.message));
      });
  };
};

export const updateSingleInventoryProductNutrition = (data, id) => {
  return async (dispatch) => {
    dispatch(updateSingleInventoryNutrition(data, id));
    dispatch(enableLoading());
    await api
      .updateSingleInventoryNutrition(data, id)
      .then((res) => {
        dispatch(disableLoading());
        if (res.status === 200) {
          dispatch(updateInventoryNutritionSuccess(res.data));
        } else {
          dispatch(updateInventoryNutritionFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(disableLoading());
        dispatch(updateInventoryNutritionFailed(err.message));
      });
  };
};

export function resetErrors() {
  return {
    type: types.RESET_ERRORS,
  };
}

// generate gl pin

export function generateGlPin(id) {
  return {
    type: types.GENERATE_GL_PIN,
  };
}

export function generateGlPinSuccess(response) {
  return {
    type: types.GENERATE_GL_PIN_SUCCESS,
    payload: response.glpin,
  };
}

export function generateGlPinFailed(error) {
  return {
    type: types.GENERATE_GL_PIN_FAILED,
    message: error,
  };
}

export const requestGenerateGlPin = () => {
  return async (dispatch) => {
    dispatch(generateGlPin());
    dispatch(enableLoading());
    await api
      .generateGlPin()
      .then((res) => {
        dispatch(disableLoading());
        if (res.status === 201) {
          console.log(res.data, "glpin");
          dispatch(generateGlPinSuccess(res.data));
        } else {
          dispatch(generateGlPinFailed(res.message));
        }
      })
      .catch((err) => {
        dispatch(disableLoading());
        dispatch(generateGlPinFailed(err.message));
      });
  };
};
