import React from "react";
import PropTypes from "prop-types";
import "./glp-header.scss";
import { Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router";

GlpHeader.propTypes = {
  logo: PropTypes.element,
  rightView: PropTypes.func,
  centerView: PropTypes.func,
};

function GlpHeader({ logo, centerView, rightView }) {
  const history = useHistory();
  const handleGotoHome = () => {
    history.push('/');
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="justify-content-between">
      <Navbar.Brand href="/">
        <div className="glp-header__left__logo" onClick={handleGotoHome}>{logo}</div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {centerView()}
        {rightView()}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default GlpHeader;
