import React from "react";
import GlpCheckbox from "../GlpCheckbox/GlpCheckbox";

import "./glp-recipe-ingredient.scss";

function GlpRecipeIngredient({ ingredient, ...props }) {
  const _ingredientItem = () => {
    const ingredientList = ingredient.map((item) => (
      <div className="glp-recipe-ingredient__item" key={item.id}>
        <div className="glp-recipe-ingredient__item__name">
          {item.name && (
            <GlpCheckbox
              id={item.id}
              name="ingredient"
              checked={item.hasIngredient}
              primary={false}
              label={item.name}
              onChange={() => console.log("on Change")}
            />
          )}
        </div>
        <div className="glp-recipe-ingredient__item__quantity">
          {item.quantity && <span>{item.quantity}</span>}
        </div>
        <div className="glp-recipe-ingredient__item__calorie">
          {item.calorie && <span>{item.calorie}</span>}
        </div>
      </div>
    ));
    return (
      <div className={"glp-recipe-ingredient__items"}>{ingredientList}</div>
    );
  };

  return (
    <div className={"glp-recipe-ingredient"}>
      <div className="glp-recipe-ingredient__title">Ingredients</div>
      <div className="glp-recipe-ingredient__body">
        {ingredient && _ingredientItem()}
      </div>
    </div>
  );
}

export default GlpRecipeIngredient;
