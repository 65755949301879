import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { requestSingleInventory } from "../../../store/actions/ProductDetails";
import AssignGlpin from "./AssignGlpin";
import ProductInformation from "./ProductInformation";
import NutritionInformation from "./NutritionInformation";
import ImagesUpdate from "./ImagesUpdate";
import { useHistory, useParams } from "react-router-dom";
import { setProductsForEditing } from "../../../store/actions/InitialInventory/actions";

function ProductDetails({ ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  const selectedProductsForEditing = useSelector(
    (state) => state.initialInventory.selectedProductsForEditing
  );

  const singleInventoryData = useSelector(
    (state) => state.productDetailsReducer?.singleInventoryData
  );

  const glpProductId = useSelector(
    (state) => state.productDetailsReducer?.singleInventoryData?.GlpProduct?.id
  );

  const initialInventory = useSelector(
    (state) => state.initialInventory.products.data
  );

  const selectedInventories = selectedProductsForEditing.rowData;

  const [selectedProductItemId, setSelectedProductItemId] = useState(
    selectedProductsForEditing.ids[0]
  );

  useEffect(() => {
    dispatch(requestSingleInventory(selectedProductItemId));
  }, [selectedProductItemId]);

  useEffect(() => {
    return () => {
      dispatch(setProductsForEditing([]));
    };
  }, []);

  useEffect(() => {
    setSelectedProductItemId(parseInt(id));
  }, []);

  const handleSelectCurrentProduct = (productItemId) => {
    setSelectedProductItemId(productItemId);
    history.replace({ pathname: `/product-details/${productItemId}` });
  };

  const [key, setKey] = useState();

  const handleTabChange = (k) => {
    setKey(k);
  };
  const detailTabs = [
    {
      title: "Assign glpin",
      key: "glpin",
      component: <AssignGlpin setKey={setKey} />,
    },
    {
      title: "Product information update",
      key: "productInfoUpdate",
      component: <ProductInformation setKey={setKey} />,
    },
    {
      title: "Nutrition information update",
      key: "nutritionInfoUpdate",
      component: <NutritionInformation setKey={setKey} />,
    },
    {
      title: "Images update",
      key: "imageUpdate",
      component: singleInventoryData ? (
        <ImagesUpdate
          setKey={setKey}
          setSelectedProductItemId={setSelectedProductItemId}
          selectedProductItemId={selectedProductItemId}
          glpProductId={glpProductId}
        />
      ) : null,
    },
  ];

  return (
    <>
      <div className="main-bg product-details">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-11">
              <div className="glp-profile-relative glp-product-detail rounded border">
                <div className="glp-top-section">
                  <div id="features" className="glp-profile-section">
                    <h1 className="page-headings">Product Details</h1>
                  </div>
                </div>
                <div className="main-section">
                  <div className="row no-gutters">
                    <div className="col-sm-3">
                      <div className="white-bg">
                        <div className="left-content mt-3 pt-4">
                          {selectedInventories.map((productItem, index) => {
                            return (
                              <a
                                href
                                className={
                                  selectedProductItemId === productItem.id
                                    ? `active`
                                    : ""
                                }
                                onClick={() =>
                                  handleSelectCurrentProduct(productItem.id)
                                }
                                key={`productList_${index}`}
                              >
                                <h6>{productItem.productName}</h6>
                                <div className="d-flex bottom-text flex-wrap">
                                  <span className="text-grey mr-2">UPC</span>
                                  <span className="text-green mr-2">
                                    {productItem.upc}
                                  </span>
                                  <span className="text-grey  ml-1">
                                    Size{" "}
                                    <strong className="pl-1">
                                      {productItem?.size?.split(" ")[0]}
                                    </strong>
                                  </span>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-9">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => handleTabChange(k)}
                      >
                        {detailTabs.map(function (item, index) {
                          return (
                            <Tab
                              eventKey={item.key}
                              title={item.title}
                              key={`tabs__${index}`}
                            >
                              <div className="glp-profile-content product-inventory">
                                {item.component}
                              </div>
                            </Tab>
                          );
                        })}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
