import React from 'react';
import MainModal from './MainModal';
import {Row, Col} from 'react-bootstrap';
import GlpButton from './../../Gcomponents/GlpButton/GlpButton';

function DeleteModal(props) {
  return (
      <MainModal
          {...props}
          modalname="Delete"
          header={() => 'Delete Item'}
          body={() => (
              <Row>
                <Col>
                  <div className="right-side glp-content">
                    <div className="mid-container login">
                      <div className="fadeIn first mx-4">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's
                          standard dummy text ever since the 1500s,
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
          )}
          footer={() => (
              <div className="row w-100 mb-md-4">
                <div className="col-lg-4">
                  <div className="mr-4"/>
                </div>
                <div className="col-lg-8">
                  <div className="d-flex justify-content-end">
                    <div className="mr-2">
                      <GlpButton label="CANCEL" onClick={props.onHide}/>
                    </div>
                    <div className="">
                      <GlpButton color="danger" label="DELETE"
                                 onClick={props.onHide}/>
                    </div>
                  </div>
                </div>
              </div>
          )}
      />
  );
}

export default DeleteModal;
