import React from "react";
import MainModal from "./MainModal";
import { Row, Col } from "react-bootstrap";

function ContractDetails({ ...props }) {
  return (
    <MainModal
      {...props}
      modalname="Contract"
      header={() => "Contract Details"}
      body={() => (
        <Row>
          <Col>
            <div className="right-side glp-content">
              <div className="mid-container login">
                <div className="fadeIn first mx-4">
                  {props.details ? props.details() : ""}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    />
  );
}

export default ContractDetails;
