import React from "react";
import PropTypes from "prop-types";

import "./glp-news-letter.scss";

GlpNewsletter.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  width: PropTypes.number,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
};

GlpNewsletter.defaultProps = {
  onChange: () => console.log("on change input"),
  onSubmit: ($event) => { $event.preventdefault(); console.log("on submit input") },
};

function GlpNewsletter({
  id,
  label,
  description,
  onChange,
  onSubmit,
  icon,
  width,
  ...props
}) {
  const style = {
    ...(width && { width: `${width}px` }),
  };
  return (
    <form className="glp-newsletter" style={style}>
      {label && <label>{label}</label>}
      <div className="glp-newsletter__view">
        <input
          id={id}
          type="email"
          className="glp-newsletter__view__text"
          onChange={onChange}
          {...props}
        />
        {icon && <button type="submit" onSubmit={onSubmit} className="glp-newsletter__view__icon">{icon}</button>}
      </div>
      {description && (
        <div className="glp-newsletter__description">{description}</div>
      )}
    </form>
  );
}

export default GlpNewsletter;
