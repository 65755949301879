import React, { Component } from "react";
import phoneImg from "./../../Gcomponents/GlpFeatureCallout/img/call 1.png";
import bellImg from "./../../Gcomponents/GlpFeatureCallout/img/vector.png";
import statsImg from "./../../Gcomponents/GlpFeatureCallout/img/dashboard 1.png";
import achievementImg from "./../../Gcomponents/GlpFeatureCallout/img/XMLID 1504.png";

import GlpFeatureCallout, {
  GlpFeatureCalloutItem,
} from "./../../Gcomponents/GlpFeatureCallout/GlpFeatureCallout";

class FeatureCallout extends Component {
  render() {
    return (
      <div id="works">
        <GlpFeatureCallout
          title="DISCOVER OUR GREAT FEATURE"
          description="ENJOY ALL OF OUR CUSTOM COMPONENTS"
        >
          <GlpFeatureCalloutItem
            title="Create Grocery Lists"
            description="Whether you’re taking a break at work or waiting for the kids’ practice to be over, access Grocery List Plus on any of your devices with a single account."
            icon={<img src={phoneImg} alt="Create Grocery Lists" />}
          />
          <GlpFeatureCalloutItem
            title="Create Grocery Lists"
            description="Set your user preferences to decide how often, and to which devices, you receive alerts and notifications."
            icon={<img src={bellImg} alt="Create Grocery Lists" />}
          />
          <GlpFeatureCalloutItem
            title="Create Grocery Lists"
            description="See a report on your shopping habits—from how often and when you are shopping to your average bill or how much you’ve saved using the app."
            icon={<img src={statsImg} alt="Create Grocery Lists" />}
          />
          <GlpFeatureCalloutItem
            title="Create Grocery Lists"
            description="Based on the rest of your list, Grocery List Plus will recommend complementary items to add to your list."
            icon={<img src={achievementImg} alt="Create Grocery Lists" />}
          />
        </GlpFeatureCallout>
      </div>
    );
  }
}

export default FeatureCallout;
