export const INITIALS = {
  loginModal: {
    userId: "",
    password: "",
  },
  registerModal: {
    firstName: "",
    lastName: "",
    email: "",
    zipCode: "",
    password: "",
    termsid: false,
  },
  forgetPasswordModal: {
    email: "",
  },
  assignGlpin: {
    glpin: "",
    productName: "",
    brandName: "",
    upc: "",
    size: "",
  },
  productInformation: {
    brandName: "",
    productName: "",
    systemDept: "",
    priceAvg: "",
    systemCategory: "",
    productDescriptionShort: "",
  },
  nutritionInformation: {
    glpServingSizeQty: "",
    glpServingsPerContainer: "",
    glpCalories: "",
    glpCaloriesFromFat: "",
    glpTotalFat: "",
    glpSaturatedFat: "",
    glpPolyunsaturatedFat: "",
    glpMonounsaturatedFat: "",
    glpCholesterol: "",
    glpSodium: "",
    glpPotassiumDv: "",
    glpTotalCarbohydrate: "",
    glpDietaryFiber: "",
    glpSugars: "",
    glpProtein: "",
    glpVitaminADv: "",
    glpVitaminCDv: "",
    glpCalciumDv: "",
    glpIronDv: "",
    glpVitaminDDv: "",
  },
};
