import createReducer from "../../helpers/createReducer";
import * as types from "../actions/ProductDetails/types";

const initialState = {
  isLoading: false,
  errors: null,
  singleInventoryData: null,
  glpin: null,
};

export const productDetailsReducer = createReducer(initialState, {
  [types.GET_SINGLE_INVENTORY](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [types.UPDATE_SINGLE_INVENTORY](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [types.UPDATE_SINGLE_INVENTORY_NUTRITION](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [types.SINGLE_INVENTORY_SUCCESSS](state, action) {
    return {
      ...state,
      isLoading: false,
      singleInventoryData: action.payload,
      errors: null,
    };
  },
  [types.SINGLE_INVENTORY_FAILED](state, action) {
    return {
      ...state,
      isLoading: false,
      errors: action.message,
    };
  },
  [types.RESET_ERRORS](state, action) {
    return {
      ...state,
      errors: null,
    };
  },
  [types.GENERATE_GL_PIN](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [types.GENERATE_GL_PIN_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      glpin: action.payload
    };
  },
  [types.GENERATE_GL_PIN_FAILED](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.message
    };
  },
});
