import React from "react";
import PropTypes from "prop-types";
import "./glp-badge.scss";

GlpBadge.propTypes = {
  badge: PropTypes.node,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["default", "primary", "secondary"]),
  mode: PropTypes.oneOf(["sm", "md", "lg"]),
};

GlpBadge.defaultProps = {
  variant: "default",
  badge: 1,
  mode: "sm",
};

function GlpBadge({ children, color, variant, badge, mode, ...props }) {
  const badgeClassName = `glp-badge glp-badge__${variant} glp-badge__${mode}`;
  return (
    <div className={badgeClassName}>
      {children}
      <span {...props}>{badge}</span>
    </div>
  );
}

export default GlpBadge;
