import createReducer from "../../helpers/createReducer";
import { types } from "../actions/InitialInventory";

const initialState = {
  isLoading: false,
  products: {
    isLoading: false,
    isLoadMore: false,
    data: [],
    page: null,
    limit: null,
    totalPages: null,
    totalResults: null,
  },
  error: null,
  success: null,
  data: [],
  selectedProductsForEditing: null,
  productSearchQuery: null
};

export const initialInventory = createReducer(initialState, {
  [types.FETCH_INITIAL_INVENTORY](state, action) {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: true,
      },
    };
  },
  [types.FETCH_INITIAL_INVENTORY_SUCCESS](state, action) {
    const { data, page, limit, totalPages, totalResults } = action.payload;
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
        data: action.payload.results,
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages,
        totalResults,
      },
    };
  },
  [types.FETCH_INITIAL_INVENTORY_FAILED](state, action) {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
      },
      error: action.payload,
    };
  },
  [types.SET_PRODUCTS_FOR_EDITING](state, action) {
    return {
      ...state,
      selectedProductsForEditing: action.payload || null,
    };
  },
  [types.SET_PRODUCT_SEARCH_QUERY](state, action) {
    return {
      ...state,
      productSearchQuery: action.payload,
    };
  },
});
