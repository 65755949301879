import React, { useRef, useEffect, useState } from "react";
import GlpInput from "./../../../Gcomponents/GlpInput/GlpInput";
import GlpButton from "./../../../Gcomponents/GlpButton/GlpButton";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { INITIALS, STRINGS } from "../../../constants";
import { ValidationUtils } from "../../../utils";
import Errors from "../../Errors/Errors";
import {
  resetErrors,
  updateSingleInventoryProduct,
} from "../../../store/actions/ProductDetails";
import { useHistory } from "react-router-dom";
import GlpLoader from "../../GlpLoader/GlpLoader";

function ProductInformation(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const singleInventoryData = useSelector(
    (state) => state.productDetailsReducer.singleInventoryData
  );

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  useEffect(() => {
    formikRef &&
      formikRef.current &&
      formikRef.current.setErrors({
        productName: null,
        systemDept: null,
        brandName: null,
        priceAvg: null,
        systemCategory: null,
        productDescriptionShort: null,
      });
    if (
      singleInventoryData?.GlpProduct !== null &&
      formikRef &&
      formikRef.current
    ) {
      const productDetails = singleInventoryData?.GlpProduct;
      const productDetailsPrice = productDetails?.ProductPrices?.filter(
        (item) => item.status === "active" && item.priceAvg !== 0
      );

      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.productName,
        productDetails?.productName ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.department,
        productDetails?.systemDept ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.brand,
        productDetails?.brandName ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.price,
        productDetailsPrice[0]?.priceAvg ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.category,
        productDetails?.systemCategory ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.productDesc,
        productDetails?.productDescriptionShort ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.productInformation.ingredients,
        productDetails?.ingredients ?? ""
      );
    }
  }, [singleInventoryData]);

  const [type, setType] = useState(null);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={INITIALS.productInformation}
        validationSchema={ValidationUtils.ProductInformationValidation}
        onSubmit={(values, { setSubmitting }) => {
          const productId = singleInventoryData?.GlpProduct.id;
          dispatch(updateSingleInventoryProduct(values, productId));
          type === "exit"
            ? history.push("/product-management")
            : props.setKey("nutritionInfoUpdate");
        }}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="mt-4 mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation.brandLabel
                          }
                          name={STRINGS.productDetails.productInformation.brand}
                          onChange={handleChange}
                          variant="flat"
                          value={values.brandName}
                        />
                        <Errors
                          error={errors.brandName}
                          showError={errors.brandName}
                        />
                      </div>

                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .productNameLabel
                          }
                          name={
                            STRINGS.productDetails.productInformation
                              .productName
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.productName}
                        />
                        <Errors
                          error={errors.productName}
                          showError={errors.productName}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .departmentLabel
                          }
                          name={
                            STRINGS.productDetails.productInformation.department
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.systemDept}
                        />
                        <Errors
                          error={errors.systemDept}
                          showError={errors.systemDept}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation.priceLabel
                          }
                          name={STRINGS.productDetails.productInformation.price}
                          onChange={handleChange}
                          variant="flat"
                          value={values.priceAvg}
                        />
                        <Errors
                          error={errors.priceAvg}
                          showError={errors.priceAvg}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .categoryLabel
                          }
                          name={
                            STRINGS.productDetails.productInformation.category
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.systemCategory}
                        />
                        <Errors
                          error={errors.systemCategory}
                          showError={errors.systemCategory}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .productDescLabel
                          }
                          name={
                            STRINGS.productDetails.productInformation
                              .productDesc
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.productDescriptionShort}
                        />
                        <Errors
                          error={errors.productDescriptionShort}
                          showError={errors.productDescriptionShort}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .ingredientsLabel
                          }
                          name={
                            STRINGS.productDetails.productInformation
                              .ingredients
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.ingredients}
                        />
                        {/* <Errors
                          error={errors.ingredients}
                          showError={errors.ingredients}
                        /> */}
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.productInformation
                              .instructionsLabel
                          }
                          name="input"
                          onChange={handleChange}
                          variant="flat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-8 text-right">
                      <div className="mt-4 d-flex justify-content-end">
                        <div>
                          <GlpButton
                            color="secondary"
                            label="SAVE AND EXIT"
                            onClick={(e) => {
                              setType("exit");
                              handleSubmit(e);
                            }}
                            variant="outline"
                          />
                        </div>

                        <div className="ml-3">
                          <GlpButton
                            label="Save And Continue"
                            onClick={(e) => {
                              setType("continue");
                              handleSubmit(e);
                            }}
                            variant="outline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      {loadingReducer.isLoading && <GlpLoader />}
    </>
  );
}

export default ProductInformation;
