import React, { useState } from "react";
import GlpLink from "../../../Gcomponents/GlpLink/GlpLink";
import ContractDetails from "../../Modal/ContractDetails";
import { contractDetails } from "../data/contractDetails";

const ContractCellRender = (props) => {
  const [editModel, setEditModel] = useState(false);
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const onClick = () => {
    setEditModel(true);
  };
  return (
    <span>
      <GlpLink onClick={onClick} color="#EC1C24" underline={false}>
        {cellValue}
      </GlpLink>
      <ContractDetails
        details={contractDetails}
        show={editModel}
        onHide={() => setEditModel(false)}
      />
    </span>
  );
};

export default ContractCellRender;
