import React from 'react'
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import Generalheader from './GeneralHeader';
import MainHeader from './MainHeader';

function Header() {
    const initialLoggedInState = useSelector(state => state.authReducer.isLoggedIn)
    const [loggedIn, setLoggedIn] = useState(initialLoggedInState);
    useEffect(() => {
        const unsubscribe = store.subscribe(() => store.getState().authReducer.isLoggedIn ? setLoggedIn(true) : setLoggedIn(false));
        return unsubscribe;
    }, [])
    return (
        <>
            {
                initialLoggedInState ? <Generalheader /> : <MainHeader />
            }
        </>
    )
}

export default Header
