import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Logo } from './img/logo.svg';
import invertedTriangleImg from './img/inverted-triangle.png'
import './glp-price-card.scss';

function GlpPriceCard({
  color,
  label,
  size,
  showIcon,
  icon,
  primary,
  features,
  price,
  description,
  ButtonComponent,
  ...props
}) {
  const colorClass = (f = '') => ({
    [`glp-price-card${f}--default`]: color === 'default',
    [`glp-price-card${f}--primary`]: color === 'primary',
    [`glp-price-card${f}--secondary`]: color === 'secondary',
    [`glp-price-card${f}--info`]: color === 'info',
  });

  const headerClass = classNames({
    'glp-price-card__header': true,
    'glp-price-card__header__active': primary,
    ...colorClass('__header'),
  });

  const cardClasses = classNames({
    'glp-price-card': true,
    [`glp-price-card--sm`]: size === 'sm',
    [`glp-price-card--md`]: size === 'md',
    [`glp-price-card--lg`]: size === 'lg',
    ...colorClass(),
  });

  const bodyClass = classNames({
    ...colorClass('__body'),
  });

  const shapeClass = classNames({
    'glp-price-card__popular': true,
    ...colorClass('__popular'),
  });

  const borderClass = classNames({
    'glp-price-card__border': true,
    ...colorClass('__border'),
  });

  const _featureItem = () => {
    const lists = features.map((feature) => (
      <li className="glp-price-card__feature__item" key={feature}>
        {feature}
      </li>
    ));
    return <ul className={'glp-price-card__feature'}>{lists}</ul>;
  };

  const _header = () => {
    return (
      <React.Fragment>
        <h2 className="glp-price-card__header__text">{label}</h2>
        {primary && (
          <span className="glp-price-card__header__help">Recommended</span>
        )}
      </React.Fragment>
    );
  };

  const header = showIcon ? icon ? icon() : <Logo /> : _header();

  return (
    <div className={cardClasses}>
      {!primary && <div className={headerClass}>{header}</div>}
      {primary && (
        <div className={'triangle-border'}>
          {/* <div className={shapeClass} /> */}
          <div className="position-relative">
            <img src={invertedTriangleImg} />
            <div className="inverted-triangle">{header}</div>
          </div>
        </div>
      )}
      <div className="glp-price-card__body">
        <div className="glp-price-card__body__top">
          <p className="glp-price-card__body__text">{description}</p>
          <p className={bodyClass}>{price}</p>
        </div>
        <div className="glp-price-card__body__content">
          {features && _featureItem()}
        </div>
        <div className="glp-price-card__body__footer">
          {ButtonComponent && ButtonComponent}
        </div>
      </div>
    </div>
  );
}

GlpPriceCard.propTypes = {
  price: PropTypes.string.isRequired,
  label: PropTypes.string,
  features: PropTypes.array,
  primary: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  description: PropTypes.string.isRequired,
  icon: PropTypes.func,
  showIcon: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'info']),
  ButtonComponent: PropTypes.element,
};

GlpPriceCard.defaultProps = {
  color: 'default',

  showIcon: false,
  primary: false,
};

export default GlpPriceCard;
