import React from "react";
import PropTypes from "prop-types";
import "./glp-search.scss";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../store/actions/InitialInventory/actions";

function GlpSearch({ data, onChange, searchButton, itemCell, ...props }) {
  const dispatch = useDispatch();
  const handleProductManagementSearch = () => {
    dispatch(setSearchQuery(document.querySelector('.glp-search__view__text.glp-search-view-text1').value));
  }

  return (
    <div className="glp-search">
      <div className="glp-search__view">
        <input
          type="text"
          className="glp-search__view__text glp-search-view-text1"
          onChange={onChange}
          {...props}
        />

        {searchButton && (
          <button
            data-testid="test-search-button"
            className={`glp-search__view__button`}
            onClick={handleProductManagementSearch}
          >
            {searchButton}
          </button>
        )}
      </div>
      {data.length > 0 && (
        <div className="glp-search__overlay-view">
          <ul data-testid="test-search-item">
            {data.map((item) => (
              <li className="glp-search__overlay-view__item" key={item.id}>
                {itemCell(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

GlpSearch.defaultProps = {
  data: [],
  onChange: () => console.log("on change input"),
};

GlpSearch.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  itemCell: PropTypes.func,
  onChange: PropTypes.func,
  searchButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default GlpSearch;
