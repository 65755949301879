import React, { Component } from 'react';
import GlpFeatureCard from './../../Gcomponents/GlpFeatureCard/GlpFeatureCard';
import ListSvg from './../../Gcomponents/GlpFeatureCard/img/list.svg';
import DeviceSvg from './../../Gcomponents/GlpFeatureCard/img/device.svg';
import RecipesSvg from './../../Gcomponents/GlpFeatureCard/img/recipes.svg';
import MealSvg from './../../Gcomponents/GlpFeatureCard/img/meal.svg';
import AutoSvg from './../../Gcomponents/GlpFeatureCard/img/auto-lists.svg';
import GrocerySvg from './../../Gcomponents/GlpFeatureCard/img/grocery.svg';
import MealMakerSvg from './../../Gcomponents/GlpFeatureCard/img/meal-maker.svg';
import SavingsSvg from './../../Gcomponents/GlpFeatureCard/img/savings.svg';

const Icon = () => <img src={ListSvg} alt="Create Grocery list" />;
const DeviceIcon = () => <img src={DeviceSvg} alt="Create Grocery list" />;
const RecipesIcon = () => <img src={RecipesSvg} alt="Recipes" />;
const MealIcon = () => <img src={MealSvg} alt="Meal Planner" />;
const AutoIcon = () => <img src={AutoSvg} alt="Auto-generated lists" />;
const GroceryIcon = () => <img src={GrocerySvg} alt="Share Grocery lists" />;
const MealMakerIcon = () => <img src={MealMakerSvg} alt="Meal Maker" />;
const SavingsIcon = () => <img src={SavingsSvg} alt="Savings Notifications" />;
const Link = () => <span>Details</span>;

class Features extends Component {
  render() {
    return (
      <div className="container">
        <div className="glp-feature-relative">
          <div id="features" className="glp-feature-section">
            <h1 className="text-center pt-3 mb-4 pb-3">FEATURES</h1>
            <div className="row">
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Create Grocery lists"
                  description="Grocery List Plus lets you create a shopping list of all your current or future grocery needs. Quickly and easily add any items to it between trips to the "
                  icon={() => <Icon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Sync Your Devices"
                  description="Build your list on your tablet or laptop? Not to worry, Grocery List Plus allows you to sync your lists across all your devices, including your mobile phone "
                  icon={() => <DeviceIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Recipes"
                  description="Search our collection of recipes, add your favorite recipe, or select our Recipe of the Day. We'll let you know an estimated cost for the ingredients and "
                  icon={() => <RecipesIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Meal Planner"
                  description="Plan breakfast, lunch, and dinner for you and/or your entire family for up to 30 days in advance. As you add each meal or snack the required ingredients "
                  icon={() => <MealIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Auto-Generated Lists"
                  description="Mark your favorites as recurring and they will be added whenever a new list is created. Not impressive enough for you? The more you use the app our "
                  icon={() => <AutoIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Share Grocery Lists"
                  description="Invite your friends to Grocery List Plus and you can set up groups that allow you to share your lists with your family, roommates or friends on their devices."
                  icon={() => <GroceryIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Meal Maker"
                  description="Let Grocery List Plus suggest meals for you based on what’s on your list, ingredients in your pantry or by your budget."
                  icon={() => <MealMakerIcon />}
                  link={() => <Link />}
                />
              </div>
              <div className="col col-md-4 col-xl-3">
                <GlpFeatureCard
                  title="Savings Notifications"
                  description="Tired of scanning ads for the best deals? Not only will Grocery List Plus recommend coupons for you based on your shopping history it will also review your grocery list against current store"
                  icon={() => <SavingsIcon />}
                  link={() => <Link />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
