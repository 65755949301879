const Api = {
  // BaseURL: "http://localhost:8080/v1/",
  BaseURL: "https://gl.rsysdemo.com/v1/",
  // BaseURL: "http://localhost:3000/v1/",
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  REGISTER: "users/register",
  FORGET_PASSWORD: "users/forget-password",
  SINGLE_INVENTORY: "initialInventories/product",
  UPDATE_SINGLE_INVENTORY: "glpProducts",
  INITIAL_INVENTORIES: "initialInventories",
  INITIAL_INVENTORIES_PRODUCT: "initialInventories/products",
  GLP_PRODUCTS: "glpProducts/",
  GENERATE_GLPIN: "glpProducts/glpin",
  REFRESH_TOKENS: "auth/refresh-tokens",
};

export { Api };
