import createReducer from "../../helpers/createReducer";
import * as types from "../actions/Loading/types";

const initialState = {
  isLoading: false
}

export const loadingReducer = createReducer(initialState, {
  [types.ENABLE_LOADER](state, action) {
    return {
      ...state,
      isLoading: true
    }
  },
  [types.DISABLE_LOADER](state, action) {
    return {
      ...state,
      isLoading: false
    }
  }
});
