import React, { useRef, useEffect, useState } from "react";
import GlpInput from "./../../../Gcomponents/GlpInput/GlpInput";
import GlpButton from "./../../../Gcomponents/GlpButton/GlpButton";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { INITIALS } from "../../../constants";
import { STRINGS } from "./../../../constants/Strings";
import { updateSingleInventoryProductNutrition } from "../../../store/actions/ProductDetails";
import { useHistory } from "react-router-dom";
import GlpLoader from "../../GlpLoader/GlpLoader";

function NutritionInformation(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const singleInventoryData = useSelector(
    (state) => state.productDetailsReducer.singleInventoryData
  );

  useEffect(() => {
    if (singleInventoryData && formikRef && formikRef.current) {
      const productDetails =
        singleInventoryData?.GlpProduct?.ProductNutritionInfo;
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.servingSize,
        productDetails?.glpServingSizeQty ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.servingPerContainer,
        productDetails?.glpServingsPerContainer ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.calories,
        productDetails?.glpCalories ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.caloriesFromFat,
        productDetails?.glpCaloriesFromFat ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.totalFat,
        productDetails?.glpTotalFat ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.saturatedFat,
        productDetails?.glpSaturatedFat ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.polyUnsaturatedFat,
        productDetails?.glpPolyunsaturatedFat ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.monoUnsaturatedFat,
        productDetails?.glpMonounsaturatedFat ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.cholesterol,
        productDetails?.glpCholesterol ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.sodium,
        productDetails?.glpSodium ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.potassium,
        productDetails?.glpPotassiumDv ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.carbohydrate,
        productDetails?.glpTotalCarbohydrate ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.dietaryFiber,
        productDetails?.glpDietaryFiber ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.sugar,
        productDetails?.glpSugars ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.protein,
        productDetails?.glpProtein ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.vitaminA,
        productDetails?.glpVitaminADv ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.vitaminC,
        productDetails?.glpVitaminCDv ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.calcium,
        productDetails?.glpCalciumDv ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.iron,
        productDetails?.glpIronDv ?? ""
      );
      formikRef.current.setFieldValue(
        STRINGS.productDetails.nutritionInformation.vitaminDd,
        productDetails?.glpVitaminDDv ?? ""
      );
    }
  }, [singleInventoryData]);

  const [type, setType] = useState(null);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={INITIALS.nutritionInformation}
        onSubmit={(values, { setSubmitting }) => {
          const productId = singleInventoryData?.GlpProduct.id;
          dispatch(updateSingleInventoryProductNutrition(values, productId));
          type === "exit"
            ? history.push("/product-management")
            : props.setKey("imageUpdate");
        }}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="mt-4 mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .servingSizeLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .servingSize
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpServingSizeQty}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .servingPerContainerLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .servingPerContainer
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpServingsPerContainer}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .caloriesLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.calories
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpCalories}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .caloriesFromFatLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .caloriesFromFat
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpCaloriesFromFat}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .totalFatLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.totalFat
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpTotalFat}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .saturatedFatLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .saturatedFat
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpSaturatedFat}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .polyUnsaturatedFatLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .polyUnsaturatedFat
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpPolyunsaturatedFat}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .monoUnsaturatedFatLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .monoUnsaturatedFat
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpMonounsaturatedFat}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .cholesterolLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .cholesterol
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpCholesterol}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .sodiumLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.sodium
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpSodium}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .potassiumLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .potassium
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpPotassiumDv}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .carbohydrateLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .carbohydrate
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpTotalCarbohydrate}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .dietaryFiberLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .dietaryFiber
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpDietaryFiber}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .sugarLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.sugar
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpSugars}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .proteinLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.protein
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpProtein}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .vitaminALabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.vitaminA
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpVitaminADv}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .vitaminCLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.vitaminC
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpVitaminCDv}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .calciumLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.calcium
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpCalciumDv}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .ironLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation.iron
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpIronDv}
                        />
                      </div>
                      <div className="mb-3">
                        <GlpInput
                          label={
                            STRINGS.productDetails.nutritionInformation
                              .vitaminDdLabel
                          }
                          name={
                            STRINGS.productDetails.nutritionInformation
                              .vitaminDd
                          }
                          onChange={handleChange}
                          variant="flat"
                          value={values.glpVitaminDDv}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-8 text-right">
                      <div className="mt-4 d-flex justify-content-end">
                        <div>
                          <GlpButton
                            color="secondary"
                            label="SAVE AND EXIT"
                            onClick={(e) => {
                              setType("exit");
                              handleSubmit(e);
                            }}
                            variant="outline"
                          />
                        </div>

                        <div className="ml-3">
                          <GlpButton
                            label="Save And Continue"
                            onClick={(e) => {
                              setType("continue");
                              handleSubmit(e);
                            }}
                            variant="outline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      {loadingReducer.isLoading && <GlpLoader />}
    </>
  );
}

export default NutritionInformation;
