import React from "react";
import GlpHeader from "../../Gcomponents/GlpHeader/GlpHeader";
import { ReactComponent as Logo } from "./../../Gcomponents/GlpHeader/img/logo.svg";
import "./../assets/custom.css";
import { CenterView } from "./CenterView";
import {RightView } from "./RightView";

function MainHeader(props) {
  return (
    <div id="home">
      <GlpHeader
        logo={<Logo />}
        centerView={CenterView}
        rightView={RightView}
      />
    </div>
  );
}

export default MainHeader;
