import * as types from './types'

export const enableLoading = () => {
  return {
    type: types.ENABLE_LOADER
  }
}

export const disableLoading = () => {
  return {
    type: types.DISABLE_LOADER
  }
}
