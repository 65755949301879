import React from "react";
import PropTypes from "prop-types";
import "./glp-type-ahead.scss";

function GlpTypeahead({
  data,
  maxVisible,
  onChange,
  searchButton,
  itemCell,
  isFooterVisible,
  onShowMore,
  ...props
}) {
  const showData = data.length > 0 ? data.slice(0, maxVisible) : [];

  return (
    <div className="glp-type-ahead">
      <div className="glp-type-ahead__view my-3">
        <input
          type="text"
          className="glp-type-ahead__view__text"
          onChange={onChange}
          {...props}
        />

        {searchButton && (
          <button
            data-testid="test-type-ahead-search-button"
            className={`glp-type-ahead__view__button`}
          >
            {searchButton}
          </button>
        )}
      </div>
      {showData.length > 0 && (
        <div className="glp-type-ahead__overlay-view">
          <ul data-testid="test-type-ahead-search-item">
            {showData.map((item) => (
              <li className="glp-type-ahead__overlay-view__item" key={item.id}>
                {itemCell(item)}
              </li>
            ))}
          </ul>

          {isFooterVisible && (
            <div className="glp-type-ahead__overlay-view__footer">
              <button onClick={onShowMore}>
                <span> See more results</span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

GlpTypeahead.defaultProps = {
  data: [],
  maxVisible: 3,
  onShowMore: () => { },
  isFooterVisible: true,
  onChange: () => console.log("on change input"),
};

GlpTypeahead.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  maxVisible: PropTypes.number.isRequired,
  itemCell: PropTypes.func,
  onChange: PropTypes.func,
  searchButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isFooterVisible: PropTypes.bool,
  onShowMore: PropTypes.func,
};

export default GlpTypeahead;
