import React, { Component } from "react";
import { ReactComponent as FooterLogo } from "./../../Gcomponents/GlpFooter/img/footer-logo.svg";
import GlpNewsletter from "./../../Gcomponents/GlpNewsletter/GlpNewsletter";
import { ReactComponent as NewsLetter } from "./../../Gcomponents/GlpNewsletter/img/newsletter.svg";
import { Dropdown } from "react-bootstrap";
import GlpFooter from "./../../Gcomponents/GlpFooter/GlpFooter";

class Footer extends Component {
  render() {
    return (
      <GlpFooter
        logo={<FooterLogo />}
        copyright="© 2018 GL Plus, LLC. All rights reserved"
        items={() => (
          <div className="list-group">
            <a href="#">Advertise</a>
            <a href="#">F.A.Q.</a>
            <a href="#">Investor Relations</a>
            <a href="#">News</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Support</a>
            <a href="#">Terms and Conditions</a>
          </div>
        )}
        newsletter={
          <GlpNewsletter
            label="Subscription"
            description="Leo arcu id blandit aliquam, ullamcorper tellus amet pretium."
            placeholder="E-mail here"
            icon={<NewsLetter />}
          />
        }
        language={() => (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              English
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">English</Dropdown.Item>
              <Dropdown.Item href="#/action-2">French</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      />
    );
  }
}

export default Footer;
