import React from "react";

import "./glp-recipe-direction.scss";

GlpRecipeDirection.defaultProps = {
  directions: [],
};

function GlpRecipeDirection({ direction, ...props }) {
  const _directionItem = () => {
    const directionList = direction.map((item, index) => (
      <li className="glp-recipe-direction__item" key={index}>
        {item}
      </li>
    ));
    return <ol className={"glp-recipe-direction__items"}>{directionList}</ol>;
  };

  return (
    <div className={"glp-recipe-direction"}>
      <div className="glp-recipe-direction__title">Directions</div>
      <div className="glp-recipe-direction__body">
        {direction && _directionItem()}
      </div>
    </div>
  );
}

export default GlpRecipeDirection;
