import React from 'react';
import PropTypes from 'prop-types';
import './glp-feature-card.scss';

GlpFeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.func.isRequired,
  icon: PropTypes.func.isRequired,
  width: PropTypes.number,
};

function GlpFeatureCard({ title, description, icon, link, width, ...props }) {
  const styles = {
    ...(width && { width: `${width}em` }),
  };
  return (
    <div className="glp-feature-card" style={styles}>
      <div className="glp-feature-card--body">
        <div className="glp-feature-card--header">
          {icon && <div className="glp-feature-card--img">{icon()}</div>}
          <div className="glp-feature-card--title">{title}</div>
        </div>
        <div className="glp-feature-card--content">
          <div className="glp-feature-card--description">{description}</div>
        </div>
        <div className="glp-feature-card--footer">
          {link && link()} <em>>></em>
        </div>
      </div>
    </div>
  );
}

export default GlpFeatureCard;
