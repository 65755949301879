import { getInitialInventories } from "../../../api/methods";
import * as types from "./types";

export const fetchRecentlyAddedProducts = (
  userId,
  sortBy = "dateCreated%3Adesc",
  limit = 25,
  page = 1
) => {
  return {
    type: types.FETCH_RECENTLY_ADDED,
    userId: userId,
    sortBy,
    limit,
    page,
  };
};

export const fetchAllInitialInventory = (page) => {
  return async (dispatch) => {
    dispatch(fetchInitialInventory());
    await getInitialInventories(page)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchInitialInventorySuccess(res.data));
        } else {
          dispatch(fetchInitialInventoryFailure(res.message));
        }
      })
      .catch((err) => {
        dispatch(fetchInitialInventoryFailure(err.message));
      });
  };
};

export const fetchRecentlyAddedProductsSuccess = (data) => {
  return {
    type: types.FETCH_RECENTLY_ADDED_SUCCESS,
    recentlyAdded: data,
  };
};

export const fetchRecentlyAddedProductsFailed = (error) => {
  return {
    type: types.FETCH_RECENTLY_ADDED,
    error: error,
  };
};

export const setDataCollection = (dataCollectionType) => {
  return {
    type: types.SET_DATA_COLLECTION,
    dataCollectionType: dataCollectionType,
  };
};

export const addProductToInventory = (product) => {
  return {
    type: types.ADD_PRODUCT,
    product: product,
  };
};

export const postToInventory = (product) => {
  return {
    type: types.POST_INVENTORY,
    product,
  };
};

export const postToInventorySuccess = () => {
  return {
    type: types.POST_INVENTORY,
  };
};

export const postToInventoryFailed = (response) => {
  return {
    type: types.POST_INVENTORY,
    error: response.message,
  };
};

export const changeAddType = (addType) => {
  return {
    type: types.CHANGE_ADD_TYPE,
    addType: addType,
  };
};

export const resetProducts = () => {
  return {
    type: types.RESET_PRODUCTS,
  };
};

export const setUpc = (upc) => {
  return {
    type: types.SET_UPC,
    upc: upc,
  };
};

export const setError = (error) => {
  return {
    type: types.SET_ERROR,
    error: error,
  };
};

export const fetchInitialInventory = () => {
  return {
    type: types.FETCH_INITIAL_INVENTORY,
  };
};

export const fetchInitialInventorySuccess = (payload) => {
  return {
    type: types.FETCH_INITIAL_INVENTORY_SUCCESS,
    payload,
  };
};

export const fetchInitialInventoryFailure = (message) => {
  return {
    type: types.FETCH_INITIAL_INVENTORY_FAILED,
    payload: message,
  };
};

export const receiveInitialInventory = (initialInventory) => {
  return {
    type: types.RECEIVE_INITIAL_INVENTORY,
    initialInventory: initialInventory,
  };
};

export const receiveInitialInventoryFailed = (error) => {
  return {
    type: types.RECEIVE_INITIAL_INVENTORY_FAILED,
    error: error,
  };
};

export const fetchGlpProduct = (upcCode) => {
  return {
    type: types.FETCH_GLP_PRODUCT,
    upcCode: upcCode,
  };
};

export const receiveGlpProduct = (response) => {
  return {
    type: types.RECEIVE_GLP_PRODUCT,
    glpProduct: response,
  };
};

export const receiveGlpProductFailed = (error) => {
  return {
    type: types.RECEIVE_GLP_PRODUCT,
    error: error,
  };
};

export const receiveProductToAdd = (product) => {
  return {
    type: types.RECEIVE_PRODUCT_TO_ADD,
    product: product,
  };
};

export const setProductsForEdit = (products, history) => {
  return {
    type: types.SET_PRODUCTS_FOR_EDITING,
    payload: products,
    history: history
  };
};

export const setProductsForEditing = (products, history) => {
  return async (dispatch) => {
    dispatch(setProductsForEdit(products, history));
  };
};

export const getSearchQueryAction = (query) => {
  return {
    type: types.SET_PRODUCT_SEARCH_QUERY,
    payload: query,
  };
};

export const setSearchQuery = (query) => {
  return async (dispatch) => {
    dispatch(getSearchQueryAction(query));
  }
}

