import {applyMiddleware, compose, createStore} from 'redux'
import {persistStore, persistReducer, persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import reduxLogger from 'redux-logger';
import rootReducer from './reducers'
import reduxThunk from 'redux-thunk'

const config = {
    key: 'root',
    storage,
    blacklist: [],
    debug: true, // to get useful logging
}

let middlewares = [];
middlewares.push(reduxLogger);
middlewares.push(reduxThunk);

const reducers = persistCombineReducers(config, rootReducer);
const enhancers = [applyMiddleware(...middlewares)];
const persistConfig = {enhancers};
export const store = createStore(reducers, undefined, compose(...enhancers));
const persistor = persistStore(store, persistConfig, () => {
});
const configureStore = () => {
    return {persistor, store};
};

export {configureStore};
