import React from "react";
import PropTypes from "prop-types";
import "./glp-welcome-menu.scss";
import { NavDropdown } from "react-bootstrap";
import placeHolderImage from "./img/placeholder.svg";
import { greetMessage } from "./helper";

GlpWelcomeMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  href: PropTypes.string,
};

export function GlpWelcomeMenuItem({ icon, title, href, ...props }) {
  return (
    <NavDropdown.Item
      className="glp-welcome-menu__item__view"
      href={href}
      {...props}
    >
      {icon && (
        <div
          className="glp-welcome-menu__item__view__icon"
          data-testid="test-dropdown-menu-item-icon"
        >
          {icon}
        </div>
      )}
      <div
        className="glp-welcome-menu__item__view__title"
        data-testid="test-dropdown-menu-item-title"
      >
        {title}
      </div>
    </NavDropdown.Item>
  );
}

GlpWelcomeMenu.propTypes = {
  userName: PropTypes.string,
  imagePath: PropTypes.string,
};

function GlpWelcomeMenu({ userName, imagePath, children, props }) {
  return (
    <NavDropdown
      className="glp-welcome-menu"
      title={
        <div className="glp-welcome-menu__title">
          <div className="glp-welcome-menu__title__greeting">
            <div className="glp-welcome-menu__title__greeting__message">
              Good {greetMessage()}
            </div>

            <div
              className="glp-welcome-menu__title__greeting__username"
              data-testid="test-dropdown-menu-title-username"
            >
              {userName}
            </div>
          </div>
          <div className="glp-welcome-menu__avatar">
            <img
              src={imagePath ? imagePath : placeHolderImage}
              alt="Avatar"
              data-testid="test-dropdown-menu-title-user-image"
            />
          </div>
        </div>
      }
      {...props}
    >
      <div className="glp-welcome-menu__item">{children}</div>
    </NavDropdown>
  );
}

export default GlpWelcomeMenu;
