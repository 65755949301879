import createReducer from "../../helpers/createReducer";
import { types } from "../actions/Auth";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  errors: null,
  username: null,
  email: null,
  password: null,
  user: null,
  tokens: {
    access: {
      token: null,
      expiry: null,
    },
    refresh: {
      token: null,
      expiry: null,
    },
  },
};

export const authReducer = createReducer(initialState, {
  [types.REGISTER_REQUEST](state, action) {
    return {
      ...state,
      email: action.payload.email,
      password: action.payload.password,
      isLoading: true,
    };
  },
  [types.LOGIN_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
      errors: null,
    };
  },
  [types.LOGIN_SUCCESS](state, action) {
    return {
      ...state,
      isLoggedIn: true,
      isLoading: false,
      errors: null,
      user: action.employee,
      tokens: {
        access: {
          token: action.tokens.access.token,
          expiry: action.tokens.access.expires,
        },
        refresh: {
          token: action.tokens.refresh.token,
          expiry: action.tokens.refresh.expires,
        },
      },
    };
  },
  [types.LOGIN_FAILED](state, action) {
    return {
      ...state,
      errors: action.message,
      isLoading: false,
    };
  },
  [types.FORGOT_PASSWORD](state, action) {
    return {
      ...state,
      isLoading: true,
      errors: null,
    };
  },
  [types.FORGOT_PASSWORD_SUCCESS](state, action) {
    return {
      ...state,
      isLoggedIn: true,
      isLoading: false,
      errors: null,
    };
  },
  [types.FORGOT_PASSWORD_FAILED](state, action) {
    return {
      ...state,
      errors: action.message,
      isLoading: false,
    };
  },
  [types.REGISTER_SUCCESS](state, action) {
    return {
      ...state,
      isLoggedIn: true,
      isLoading: false,
      errors: null,
    };
  },
  [types.REGISTER_FAILED](state, action) {
    return {
      ...state,
      errors: action.message,
      isLoading: false,
    };
  },
  [types.RESET_ERRORS](state, action) {
    return {
      ...state,
      errors: null,
    };
  },
  [types.LOG_OUT](state, action) {
    return initialState;
  },
  [types.LOGOUT_FAILED](state, action) {
    return {
      ...state,
      errors: action.message,
    };
  },
  [types.REFRESH_TOKENS](state, action) {
    return {
      ...state,
      tokens: {
        access: action.tokens.access,
        refresh: action.tokens.refresh,
      },
    };
  },
});
