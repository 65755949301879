import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

function MainModal(props) {
  return (
      <Modal
          show={props.show}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          className={`${props?.modalname
              ? props.modalname === 'Delete'
                  ? 'user-modal delete-model'
                  : 'user-modal contract-model'
              : 'user-modal'
          }`}
          centered
      >
        {props.header ? (
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>{props.header()}</h4>
              </Modal.Title>
            </Modal.Header>
        ) : null}
        <Modal.Body className="show-grid">{props.body()}</Modal.Body>
        {props.footer ? <Modal.Footer>{props.footer()}</Modal.Footer> : null}
      </Modal>
  );
}

export default MainModal;
