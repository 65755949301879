export const nutrition = [
  {
    id: "1",
    name: "kcal",
    quantity: "180",
  },
  {
    id: "2",
    name: "Fat",
    quantity: "7g",
  },

  {
    id: "3",
    name: "Saturates",
    quantity: "4g",
  },
  {
    id: "4",
    name: "Carbs",
    quantity: "25g",
  },
  {
    id: "5",
    name: "Sugars",
    quantity: "13g",
  },

  {
    id: "6",
    name: "Fibre",
    quantity: "2g",
  },
  {
    id: "7",
    name: "Protein",
    quantity: "13g",
  },
  {
    id: "8",
    name: "Salt",
    quantity: "0.2g",
  },
];

export const ingredient = [
  {
    id: "1",
    name: "Boneless, skinless chicken breast halves ",
    hasIngredient: true,
    quantity: "6 pieces",
    calorie: "231 Kcal",
  },
  {
    id: "2",
    name: "Boneless, skinless chicken breast halves",
    hasIngredient: false,
    quantity: "6 pieces",
    calorie: "231 Kcal",
  },
  {
    id: "3",
    name: "Boneless, skinless chicken breast halves",
    hasIngredient: true,
    quantity: "6 pieces",
    calorie: "231 Kcal",
  },
  {
    id: "4",
    name: "Boneless, skinless chicken breast halves",
    hasIngredient: false,
    quantity: "6 pieces",
    calorie: "231 Kcal",
  },
];

export const direction = [
  "Preheat the oven's broiler and set the oven rack at about 6 inches from the heat source; prepare the rack of a broiler pan with cooking spray.",
  "Preheat the oven's broiler and set the oven rack at about 6 inches from the heat source; prepare the rack of a broiler pan with cooking spray.",
  "Preheat the oven's broiler and set the oven rack at about 6 inches from the heat source; prepare the rack of a broiler pan with cooking spray.",
  "Preheat the oven's broiler and set the oven rack at about 6 inches from the heat source; prepare the rack of a broiler pan with cooking spray.",
];
