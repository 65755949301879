import React from 'react';
import PropTypes from 'prop-types';

import './glp-recipe.scss';

GlpRecipe.propTypes = {
  title: PropTypes.string,
  recipe: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    nutritionComponent: PropTypes.element,
    ingredientComponent: PropTypes.element,
    directionComponent: PropTypes.element,
  }),
};

GlpRecipe.defaultProps = {};

function GlpRecipe({ title, recipe, ...props }) {
  const {
    name,
    image,
    directionComponent,
    ingredientComponent,
    nutritionComponent,
  } = recipe;
  return (
    <div className="glp-recipe">
      <div className="glp-recipe__header">
        <div className="glp-recipe__header__overlay"></div>
      </div>
      <span className="glp-recipe__title"> {title}</span>

      <div className="container-fluid">
        <div className="glp-recipe__main">
          <div className="glp-recipe__body">
            <div className="glp-recipe__body__top-container">
              <div className={'glp-recipe__body__top-container__left'}>
                {name}
              </div>
              <div className={'glp-recipe__body__top-container__right'}>
                <div
                  className={'glp-recipe__body__top-container__right__price'}
                >
                  $38.95
                </div>
                <div
                  className={
                    'glp-recipe__body__top-container__right__prepare-time'
                  }
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.09375C5.11328 0.09375 0.34375 4.86328 0.34375 10.75C0.34375 16.6367 5.11328 21.4062 11 21.4062C16.8867 21.4062 21.6562 16.6367 21.6562 10.75C21.6562 4.86328 16.8867 0.09375 11 0.09375ZM20.2812 10.75C20.2812 15.8633 16.1133 20.0312 11 20.0312C5.88672 20.0312 1.71875 15.9062 1.71875 10.75C1.71875 5.67969 5.84375 1.46875 11 1.46875C16.0703 1.46875 20.2812 5.63672 20.2812 10.75ZM13.8789 14.5742C14.0938 14.7461 14.4375 14.6602 14.5664 14.4453L14.9531 13.9727C15.125 13.7148 15.0391 13.4141 14.8242 13.2422L11.8164 11.0508V4.73438C11.8164 4.47656 11.5586 4.21875 11.3008 4.21875H10.6992C10.3984 4.21875 10.1836 4.47656 10.1836 4.73438V11.6094C10.1836 11.7812 10.2266 11.9102 10.3555 12.0391L13.8789 14.5742Z"
                      fill="white"
                    />
                  </svg>
                  <span>2h 30m</span>
                </div>
                <div
                  className={'glp-recipe__body__top-container__right__favorite'}
                >
                  <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.8516 1.45703C18.4883 -0.519531 15.0078 -0.21875 12.8164 2.05859L12 2.91797L11.1406 2.05859C9.37891 0.253906 5.85547 -0.863281 3.10547 1.45703C0.398438 3.77734 0.269531 7.90234 2.67578 10.3945L11.0117 18.9883C11.2695 19.2461 11.6133 19.375 11.957 19.375C12.3438 19.375 12.6875 19.2461 12.9453 18.9883L21.2812 10.3945C23.6875 7.90234 23.5586 3.77734 20.8516 1.45703ZM20.293 9.44922L12 18.043L3.66406 9.44922C2.03125 7.73047 1.6875 4.50781 4.00781 2.53125C6.37109 0.511719 9.12109 1.97266 10.1523 3.00391L12 4.89453L13.8047 3.00391C14.793 1.97266 17.5859 0.511719 19.9492 2.53125C22.2695 4.50781 21.9258 7.73047 20.293 9.44922Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="glp-recipe__body__bottom-container">
              <div className="glp-recipe__body__nutrition">
                {nutritionComponent && nutritionComponent}
              </div>
              <div className="glp-recipe__body__sub-container">
                <div className="glp-recipe__body__ingredient">
                  {ingredientComponent && ingredientComponent}
                </div>
                <div className="glp-recipe__body__direction">
                  {directionComponent && directionComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlpRecipe;
