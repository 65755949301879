import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Eye } from './img/eye.svg';
import { ReactComponent as BlindEye } from './img/blind-eye.svg';
import './glp-input.scss';

const Icon = <Eye viewBox="0 0 24 24" height={24} width={29} />;
const BlindIcon = <BlindEye viewBox="0 0 24 24" height={24} width={29} />;

GlpInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'textarea']),
  variant: PropTypes.oneOf(['outline', 'flat']),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.number,
  icon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

GlpInput.defaultProps = {
  type: 'text',
  variant: 'outline',
  error: false,
  icon: false,
  disabled: false,
  rows: 10,
  onChange: () => console.log('on change input'),
};

function GlpInput({
  id,
  type,
  label,
  error,
  errorMessage,
  variant,
  disabled,
  rows,
  icon,
  onChange,
  value,
  ...props
}) {
  // generic and specific
  const className = classNames({
    'glp-input': true,
    'glp-input--disabled': disabled,
  });

  const inputClass = classNames({
    [`glp-input--${variant}`]: true,
    'glp-input--error': error,
  });
  const [visible, setVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };
  return (
    <div className={className}>
      {label && (
        <label className={`glp-input--label--${variant}`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className="glp-input--control">
        {type === 'textarea' ? (
          <textarea
            id={id}
            className={inputClass}
            disabled={disabled}
            rows={rows}
            onChange={onChange}
            value={value || ""}
            {...props}
          />
        ) : type === 'password' ? (
          <input
            id={id}
            type={visible ? 'text' : 'password'}
            className={inputClass}
            disabled={disabled}
            onChange={onChange}
            value={value || ""}
            {...props}
          />
        ) : (
          <input
            id={id}
            type={type}
            className={inputClass}
            disabled={disabled}
            onChange={onChange}
            value={value || ""}
            {...props}
          />
        )}
        {icon && type === 'password' && (
          <i className={`glp-input--icon`} onClick={togglePasswordVisibility}>
            {visible ? Icon : BlindIcon}
          </i>
        )}
      </div>
      {error && errorMessage && <span>{errorMessage}</span>}
    </div>
  );
}

export default GlpInput;
