import React, { Component } from "react";
import GlpHeader from "../../Gcomponents/GlpHeader/GlpHeader";
import { ReactComponent as Logo } from "./../../Gcomponents/GlpHeader/img/logo.svg";
import GlpBadge from "./../../Gcomponents/GlpBadge/GlpBadge";
import { ReactComponent as SettingIcon } from "./../../Gcomponents/GlpHeader/img/setting.svg";
import { ReactComponent as HelpIcon } from "./../../Gcomponents/GlpHeader/img/help.svg";
import { ReactComponent as SignIcon } from "./../../Gcomponents/GlpHeader/img/sign.svg";
import { ReactComponent as Search } from "./../../Gcomponents/GlpHeader/img/search.svg";
import { ReactComponent as Notification } from "./../../Gcomponents/GlpHeader/img/notification.svg";
import GlpTypeahead from "./../../Gcomponents/GlpTypeahead/GlpTypeahead";
import { ReactComponent as StoreIcon } from "./../../Gcomponents/GlpHeader/img/store.svg";
import { ReactComponent as BellIcon } from "./../../Gcomponents/GlpHeader/img/bell.svg";
import productDetailsImg from "./../../Gcomponents/GlpHeader/img/product-details.svg";
import { Nav, Form } from "react-bootstrap";
import GlpWelcomeMenu, {
  GlpWelcomeMenuItem,
} from "./../../Gcomponents/GlpWelcomeMenu/GlpWelcomeMenu";
import userImage from "./../../Gcomponents/GlpHeader/img/user.svg";
import { useSelector, useDispatch } from "react-redux";
import { requestEmployeeLogout } from "../../store/actions/Auth";
import { useHistory } from "react-router-dom";

function Generalheader() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer);
  const logout = () => {
    dispatch(requestEmployeeLogout(history));
  };
  const navLinkStyles = { paddingLeft: 0, paddingRight: 0 };
  return (
    <GlpHeader
      logo={<Logo />}
      centerView={() => {
        return (
          <>
            <Nav className="glp-header__search">
              <GlpTypeahead placeholder="Search" searchButton={<Search />} />
            </Nav>
            <Nav className="mr-auto glp-header__inner">
              <Nav.Link
                href={
                  history.location.pathname === "/product-management"
                    ? "/"
                    : "/product-management"
                }
              >
                <div className="glp-header__right__icons">
                  <img
                    src={productDetailsImg}
                    alt="product-details-page-link"
                    style={{ width: 30, height: 30 }}
                  />
                  <div className="glp-header__right__icons__bar"></div>
                </div>
              </Nav.Link>

              <Nav.Link className="px-0" style={navLinkStyles}>
                <div className="glp-header__right__icons">
                  <GlpBadge children={<Notification />} badge={10} mode="sm" />
                  <div className="glp-header__right__icons__bar" />
                </div>
              </Nav.Link>

              <Nav.Link className="px-0" style={navLinkStyles}>
                <div className="glp-header__right__icons">
                  <GlpBadge children={<BellIcon />} badge={10} mode="sm" />
                  <div className="glp-header__right__icons__bar" />
                </div>
              </Nav.Link>
              <Nav.Link className="px-0" style={navLinkStyles}>
                <div className="glp-header__right__icons">
                  <GlpBadge children={<StoreIcon />} badge={10} mode="sm" />
                  <div className="glp-header__right__icons__bar" />
                </div>
              </Nav.Link>
              <Nav.Link className="px-0" style={navLinkStyles}>
                <span className="glp-header__right__icons__price">$ 1.94</span>
              </Nav.Link>
            </Nav>
          </>
        );
      }}
      rightView={() => {
        return (
          <Form>
            <GlpWelcomeMenu
              userName={
                loggedInUser?.user?.firstName
                  ? loggedInUser?.user?.firstName +
                    " " +
                    loggedInUser?.user?.lastName
                  : "Eliza Hart"
              }
              imagePath={userImage}
            >
              <GlpWelcomeMenuItem icon={<SettingIcon />} title="Preference" />
              <GlpWelcomeMenuItem icon={<HelpIcon />} title="Help" />
              <GlpWelcomeMenuItem
                icon={<SignIcon />}
                title="LogOut"
                onClick={logout}
              />
            </GlpWelcomeMenu>
          </Form>
        );
      }}
    />
  );
}

export default Generalheader;
