import React, { Component } from "react";
import GlpPriceCard from "./../../Gcomponents/GlpPriceCard/GlpPriceCard";
import GlpButton from "./../../Gcomponents/GlpButton/GlpButton";

const features = ["Grocery List", "Meal Planner", "Meal Maker", "Recipe"];

class Pricing extends Component {
  render() {
    return (
      <div id="pricing" className="glp-price-section">
        <div className="glp-news-section--header">
          <div className="container">
            <h1 className="glp-news-section--heading">PRICING</h1>
          </div>
        </div>
        <div className="container">
          <div className="price-card">
            <div className="row">
              <div className="col-sm col-md-4">
                <div className="card-align">
                  <GlpPriceCard
                    color="secondary"
                    description="Nibh et ut ut ac tincidunt id,Adipiscing semper nisi, luctus in tellius quarn consequat"
                    label="Free"
                    price="Free"
                    features={features}
                    ButtonComponent={
                      <GlpButton label="SIGN UP" color="secondary" />
                    }
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="card-align-mid">
                  <GlpPriceCard
                    color="primary"
                    primary={true}
                    description="Nibh et ut ut ac tincidunt id,Adipiscing semper nisi, luctus in tellius quarn consequat"
                    label="Premium"
                    price="$15/month"
                    features={features}
                    ButtonComponent={
                      <GlpButton label="SIGN UP" color="primary" />
                    }
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="card-align">
                  <GlpPriceCard
                    color="info"
                    description="Nibh et ut ut ac tincidunt id,Adipiscing semper nisi, luctus in tellius quarn consequat"
                    price="$17/month"
                    features={features}
                    showIcon={true}
                    ButtonComponent={<GlpButton label="SIGN UP" color="info" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;
