import React from 'react'
import GlpNewsCard, { GlpNewsSection } from "./../../Gcomponents/GlpNewsCard/GlpNewsCard";
import image from "./../../Gcomponents/GlpNewsCard/img/newsimage.svg";

const firstNews = {
  title:
    "ONLINE GROCERY SALES COULD REACH $100 BILLION BY 2022, RESEARCHERS SAY",
  description:
    "Instead of taking a trip to a local grocery store, more and more consumers are opting to order essentials such as cereal, toothpaste or even apples from online retail giants. ",
  image: image,
  author: "Andrea Miller",
  published_at: "March 17, 2018",
  source: "ABC News",
};

const secondNews = {
  title: "BUSY CONSUMERS OPEN TO NEW WAYS OF GROCERY SHOPPING",
  description:
    "Online ordering/delivery, meal kits become preferred solutions for some. ",
  image: image,
  author: "Russell Redman",
  published_at: " May 2, 2018",
  source: "Supermarket News",
};

const thirdNews = {
  title: "ARE GROCERS READY FOR MULTICHANNEL FULFILLMENT?",
  description:
    "Following Amazon's acquisition of Whole Foods, traditional grocers that are not already online, must find a way to enter the multichannel world. ",
  image: image,
  author: "Mary Shacklett",
  published_at: " September 15, 2017",
  source: "Food Logistics",
};

const Template = (args) => (
  <div className="container">
    <GlpNewsSection title="FEATURED NEWS">
      <GlpNewsCard news={firstNews} />
      <GlpNewsCard news={secondNews} />
      <GlpNewsCard news={thirdNews} />
    </GlpNewsSection>
  </div>
);

export const FeaturedNews = Template.bind({});

export default FeaturedNews;