import React, { useState } from "react";
import PropTypes from "prop-types";
import "./glp-news-card.scss";
import classNames from "classnames";

export function GlpNewsSection({ title, children, ...props }) {
  return (
    <div className="glp-news-section">
      <div className="glp-news-section--header">
        <h1 className="glp-news-section--heading">{title}</h1>
      </div>
      <div className="glp-news-section--body">{children}</div>
    </div>
  );
}

GlpNewsSection.propTypes = {
  title: PropTypes.string,
};

export default function GlpNewsCard({ news, ...props }) {
  const { title, description, image, author, published_at, source } = news;

  const [readMore, setReadMore] = useState(false);

  const contentClass = classNames({
    "glp-news-card__description": true,
    "glp-news-card__description-more": readMore,
  });

  return (
    <div className="glp-news-card">
      <div className="glp-news-card__header">
        {image && <img src={image} alt={title} />}
      </div>

      <div className="glp-news-card__body">
        <h2 className="glp-news-card__title">{title}</h2>
        <div className="glp-news-card__content">
          <p className={contentClass}>{description}</p>
        </div>
        <div className="glp-news-card__publish">
          <div className="glp-news-card__publish__publisher">
            {author && <span>By : {author}</span>}
            {published_at && author && (
              <span className="glp-news-card__publish__publisher-space" />
            )}
            {published_at && <span>{published_at}</span>}
          </div>
          <div className="glp-news-card__publish__publish-source">
            {source && <span>{source}</span>}
          </div>
        </div>
        <button
          className="glp-news-card__more-link"
          onClick={() => {
            setReadMore(!readMore);
          }}
        >
          {readMore ? "Read less " : "Read more "}
        </button>
      </div>
    </div>
  );
}

GlpNewsCard.propTypes = {
  news: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    author: PropTypes.string,
    published_at: PropTypes.string,
    source: PropTypes.string,
  }),
};
