import React, {Component} from 'react';
import GlpLink from './../../Gcomponents/GlpLink/GlpLink';
import {Row, Col} from 'react-bootstrap';
import MainModal from './MainModal';
import './css/modal.scss';

function EmployeeLoginModal({...props}) {
  return (
      <MainModal
          {...props}
          body={() => (
              <Row>
                <Col xs={12} md={6} className="employee-body">
                  <div className="left-side">
                    <div className="glp-content">
                      <h6 className="mb-5">Don't have an account?</h6>
                      <p>
                        Please use the link below to register a new Grocery List
                        Plus
                        account.
                      </p>
                    </div>
                    <GlpLink onClick={() => {}} underline={true}>
                      Create New account
                    </GlpLink>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="right-side glp-content">
                    <div className="brand-logo">{props.logo}</div>
                    <div className="mid-container login">
                      <div className="fadeIn pt-4 first m-3">
                        <h6>Employee Log In</h6>
                      </div>
                      {props.input()}
                    </div>
                  </div>
                </Col>
              </Row>
          )}
      />
  );
}

export default EmployeeLoginModal;
