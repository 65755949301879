import React from 'react';
import PropTypes from 'prop-types';
import './glp-link.scss';

const GlpLink = ({
  underline,
  smallUnderline,
  color,
  disabled,
  moreIcon,
  fontSize,
  to,
  children,
  ...props
}) => {

  let className = `glp-link${underline
      ? ' glp-link--underline'
      : ''}${smallUnderline ? ' glp-link--small-underline' : ''}${disabled
      ? ' glp-link--disabled'
      : ''}${moreIcon ? ' glp-link--more-icon' : ''}`;

  const styles = {
    ...(color && {color}),
    ...(fontSize && {fontSize: `${fontSize}em`}),
  };

  return (
      <div className={className}>
        <a style={styles} href={to} {...props}>
          {children}
        </a>
      </div>
  );
};

export default GlpLink;

GlpLink.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string,
  underline: PropTypes.bool,
  smallUnderline: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  moreIcon: PropTypes.bool,
  fontSize: PropTypes.number,
};

GlpLink.defaultProps = {
  disabled: false,
  underline: false,
  smallUnderline: false,
  to: '#',
  moreIcon: false,
};
