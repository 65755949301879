import React from "react";
import spinner from "../assets/images/loader.svg";

function GlpLoader() {
  return (
    <div className="position-absolute loader">
      <img src={spinner} alt="loader-img" />
    </div>
  );
}

export default GlpLoader;
