import React from 'react';
import PropTypes from 'prop-types';
import './glp-hero-banner.scss';
import Image from 'react-bootstrap/Image';
const GlpHeroBanner = ({ backgroundImage, description, title }) => {
  return (
    <div className="glp-hero-banner">
      {/* <Image src={backgroundImage} fluid className="glp-hero-banner--image" /> */}
      <div className="glp-hero-banner--shadow"></div>
      <div className="glp-hero-banner--content">
        <div className="glp-hero-banner--description">{description}</div>
        <div className="glp-hero-banner--title">{title}</div>
      </div>
    </div>
  );
};

export default GlpHeroBanner;

GlpHeroBanner.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
};

GlpHeroBanner.defaultProps = {};
