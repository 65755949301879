import React, { Component } from "react";
import GlpCallout from "./../../Gcomponents/GlpCallout/GlpCallout";
import { ReactComponent as Apple } from "./../../Gcomponents/GlpCallout/img/applestore.svg";
import { ReactComponent as Google } from "./../../Gcomponents/GlpCallout/img/playstore.svg";

import { ReactComponent as Grocery } from "./../../Gcomponents/GlpCallout/img/grocery.svg";
import { ReactComponent as Recipes } from "./../../Gcomponents/GlpCallout/img/recipeshistory.svg";

class Callout extends Component {
  render() {
    return (
      <div id="mobile">
        <GlpCallout
          frontImage={() => <Grocery />}
          backImage={() => <Recipes />}
          topContent="GET GROCERY LIST PLUS INTO"
          highlightContent="YOUR POCKET"
          description="Nibh lorem gravida sed auctor congue. Nisi in tincidunt in gravida. Molestie massa dolor turpis et."
          appStoreBtn={() => <Apple />}
          playStoreBtn={() => <Google />}
        />
      </div>
    );
  }
}

export default Callout;
